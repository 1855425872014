import { DriverState } from ".";
import { SelectOption } from "components/atoms/Select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "config";

const default_page_size = config.PAGE_SIZE;
const initialState: DriverState = {
	count: 0,
	refresh: 0,
	drivers: [],
	driver: null,
	loading: true,
	driverOptions: [],
	current_filters: {},
	refreshLoader: false,
	filters: { page: 1, page_size: default_page_size },
};

export const driverSlice = createSlice({
	name: "driver",
	initialState,
	reducers: {
		clear: (state) => {
			state.driverOptions = [];
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		addDriver: (state, action) => {
			state.drivers.unshift(action.payload);
		},
		updateDriver: (state, action) => {
			const { id, driver } = action.payload;
			state.drivers.every(({ _id }, i) => {
				if (id === _id) {
					state.drivers[i] = driver;
					return false;
				}
				return true;
			});
		},
		setDriver: (state, action) => {
			state.driver = action.payload;
		},
		setDrivers: (
			state,
			action: PayloadAction<{ drivers: any[]; count: number }>
		) => {
			const { count, drivers } = action.payload;
			let options: SelectOption[] = [];

			drivers.forEach(({ _id, last_name, first_name }: any) => {
				const option = { value: _id, label: `${first_name} ${last_name}` };
				options.push(option);
			});

			state.count = count;
			state.drivers = drivers;
			state.refreshLoader = false;
			state.driverOptions = options;
		},
		setPartnerDrivers: (state, action) => {
			const drivers = action.payload;
			let options: SelectOption[] = [];
			drivers.forEach(({ _id, last_name, first_name }: any) =>
				options.push({ value: _id, label: `${first_name} ${last_name}` })
			);
			state.driverOptions = options;
		},
		setPage: (state, action: PayloadAction<number>) => {
			state.refresh += 1;
			state.refreshLoader = true;
			state.filters.page = action.payload;
		},
		refresh: (state) => {
			state.refresh += 1;
			state.refreshLoader = true;
		},
		resetPage: (state) => {
			state.filters.page = 1;
		},
		setFilters: (state, action) => {
			state.filters = action.payload;
			state.refreshLoader = true;
		},
		setCurrentFilters: (state, action) => {
			state.current_filters = action.payload;
		},
		resetFilters: (state) => {
			state.refresh += 1;
			state.refreshLoader = true;
			state.filters = initialState.filters;
			state.current_filters = initialState.current_filters;
		},
	},
});

const driverReducer = driverSlice.reducer;

export const driverActions = driverSlice.actions;
export default driverReducer;
