import { fields } from ".";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Button from "components/atoms/Button";
import { Field, reduxForm } from "redux-form";
import { modalActions } from "redux/slices/modal";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import SmartService from "services/smart.service";
import { Grid } from "@mui/material";
import { date, required } from "utils/validate.util";
import DatePickerRedux from "components/molecules/DatePickerRedux";
import TimePickerRedux from "components/molecules/TimePickerRedux";
import InputRedux from "components/molecules/InputRedux";

function ShiftForm({ form, handleSubmit }: any) {
  const dispatch = useAppDispatch();
  const { shift, update, zone } = useAppSelector((state) => state.modal.data);
  const value = useAppSelector((state) => state.form?.["ShiftForm"]?.values);
  const {everyday=false}:any=value||{}
  const deleteShift = () => {
    SmartService.cancelShift(shift?.slot_id, zone,value?.everyday,shift?.main_id);
  
  };

  let myever=(!everyday&&!shift.everyday)?false:true
  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* <ReduxFormFields fields={fields} /> */}
        <Grid container spacing={2}>
         {(shift.everyday||!update)&& <Grid item xs={12}>
          <Field 
              name={"everyday"}
              label={"Every Day"}
              component={CheckboxRedux}
            />
          </Grid>}
          <Grid item md={myever?12:6}>
          <Field
               name= {"start_date"}
               label= {"Start Date"}
               validate= {[date, required]}
               component={ DatePickerRedux}
               DatePickerProps= {{
                 onChange: () => {},
                //  minDate: new Date(),
                 InputFieldProps: {},
                 value: { date: "", error: false },
               }}
            />
          </Grid>
          <Grid item md={6}>
          <Field
             name={"start_time"}
             label={"Start Time"}
             validate={ [date, required]}
             component={TimePickerRedux}
             TimePickerProps={{
              onChange: () => {},
              InputFieldProps: {},
              value: { date: "", error: false },
            }}
            />
          </Grid>

          {(!everyday&&!shift.everyday)&&<Grid item md={6}>
          <Field
               name= {"end_date"}
               label= {"End Date"}
               validate= {[date, required]}
               component={ DatePickerRedux}
               DatePickerProps= {{
                 onChange: () => {},
                //  minDate: new Date(),
                 InputFieldProps: {},
                 value: { date: "", error: false },
               }}
            />
          </Grid>}
          <Grid item md={6}>
          <Field
             name={"end_time"}
             label={"End Time"}
             validate={ [date, required]}
             component={TimePickerRedux}
             TimePickerProps={{
              onChange: () => {},
              InputFieldProps: {},
              value: { date: "", error: false },
            }}
            />
          </Grid>
          <Grid item md={12}>
          <Field
             name={"reason"}
             label={"Reason"}
             component={InputRedux}
            />
          </Grid>
        </Grid>
        {/* {(shift.everyday&&update) && (
          <div style={{ marginBottom: "10px" }}>
            <Field
              name={"deleteAll"}
              label={"Delete All Shift"}
              component={CheckboxRedux}
            />
          </div>
        )} */}
        <br />

        <Button
          variant="outlined"
          sx={{ marginRight: "10px" }}
          onClick={() => dispatch(modalActions.closeModal())}
        >
          Cancel
        </Button>
        {update && (
          <Button
            sx={{ marginRight: "10px" }}
            type="button"
            onClick={deleteShift}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        )}
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </form>
    </div>
  );
}

export default reduxForm({ form: "ShiftForm" })(ShiftForm);
