import React, { useEffect, useState } from "react";
import GoBack from "components/atoms/GoBack";
import Container from "@mui/material/Container";
import Banner from "components/templates/Banner";
import { useNavigate, useParams } from "react-router-dom";
import CircleLoader from "components/atoms/CircleLoader";
import AddRolesPermissionsForm from "./AddRolesPermissionsForm/AddRolesPermissionsForm";
import { Permissions, permissionCheckList } from "./AddRolesPermissionsForm";
import ToasterService from "utils/toaster.util";
import RoleService from "services/role.service";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { change } from "redux-form";


const AddRolesPermissions = () => {
  const form = "AddRolesPermissionsForm";
  const navigate = useNavigate();
  const params = useParams()
  const dispatch = useAppDispatch();



  const loading = useAppSelector((state) => state.role.loading);
  const privilege = useAppSelector((state) => state.role.privilege);

  const [permissionsList, setPermissionList] = useState<Permissions[]>([])
  const [routesList, setRoutesList] = useState([""])
  const [privilegies, setPrivilegies] = useState([""])


  useEffect(() => {
    if (params?.id) {
      RoleService.getNewRole(params?.id)
    }
  }, [params])
  useEffect(() => {
    if (params?.id) {
      if (privilege?.privilegies) {
        dispatch(change(form, "role_name", privilege.title));
        let array: any = privilege?.privilegies.length === 0 ? permissionCheckList : privilege?.privilegies
        let persmissions: any = JSON.stringify(array)
        setPermissionList(JSON.parse(persmissions))
        setRoutesList(privilege.routes)
        setPrivilegies(privilege.permissions)
      }
      return
    }
    setPermissionList(permissionCheckList)
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privilege])
  const handleSubmit = (values: any) => {
    if (routesList.every((item) => item === "")) {
      ToasterService.showError("Please Select atleast one privilege")
      return
    }
    const permisions = privilegies.filter((str) => str !== '');
    const routes = routesList.filter((str) => str !== '');

    const payload = {
      permissions: permisions,
      privilegies: permissionsList,
      title: values.role_name,
      routes: routes
    }
    if (params?.id) RoleService.updateNewRole(payload, params?.id, navigate)
    else RoleService.addNewRole(payload, navigate)

  };

  const handleChange = (e: any, permission: String) => {
    const permissionsListCopy = [...permissionsList]
    let routesListCopy = [...routesList]
    let privilegiesCopy = [...privilegies]
    if (Object.keys(e).length > 0) {
      // eslint-disable-next-line
      permissionsListCopy.filter((item, index) => {
        if (item.name === e.name) {
          if (permission === "read") {
            if (item.read) {
              // @ts-ignore
              let array = item.readRoutes
              routesListCopy = routesListCopy.filter((item) => !array.includes(item))
              const privilegeindex = privilegiesCopy.indexOf(`${item.name}:read`)
              privilegiesCopy.splice(privilegeindex, 1)
              item.read = false
            } else {
              item.read = true
              routesListCopy.push(...item.readRoutes)
              privilegiesCopy.push(`${item.name}:read`)
            }
          }
          if (permission === "write") {
            if (item.write) {
              item.write = false
              let array = item.writeRoutes
              routesListCopy = routesListCopy.filter((item) => !array.includes(item))
              const privilegeindex = privilegiesCopy.indexOf(`${item.name}:write`)
              privilegiesCopy.splice(privilegeindex, 1)
            } else {
              item.write = true
              routesListCopy.push(...item.writeRoutes)
              privilegiesCopy.push(`${item.name}:write`)

            }
          }
          if (permission === "delete") {
            if (item.delete) {
              item.delete = false
              const privilegeindex = privilegiesCopy.indexOf(`${item.name}:delete`)
              privilegiesCopy.splice(privilegeindex, 1)
            } else {
              item.delete = true
              privilegiesCopy.push(`${item.name}:delete`)

            }
          }
        }
      })
      setPrivilegies(privilegiesCopy)
      setRoutesList(routesListCopy)
      setPermissionList(permissionsListCopy)

    }

  }

  return (
    <Container>
      <GoBack path="/roles" title="Back to Roles" />
      <Banner heading="Add Role Permissions" />

      {loading && <CircleLoader />}
      {/* @ts-ignore */}
      <AddRolesPermissionsForm onSubmit={handleSubmit} permissionsList={permissionsList} handleChange={handleChange} />
    </Container>
  );
};

export default AddRolesPermissions;
