import { ReduxFormField } from "components/molecules/ReduxFormFields";
import InputRedux from "components/molecules/InputRedux";
import FileUploadRedux from "components/molecules/FileUploadRedux";
import { required } from "utils/validate.util";

export { default } from "./AddAttachmentForm";


export const fields: ReduxFormField[] = [
  {
    name: "title",
    label: "Title",
    component: InputRedux,
    validate: [required],
    cellProps: { md: 12 },
  },
  {
    name: "image",
    label: "Attachment",
    component: FileUploadRedux,
    validate: [required],
    cellProps: { md: 12 },
    FileUploadProps: {
      maxSize: 5,
      accept: [".jpg", ".jpeg", ".png", ".pdf"],
    },
  },
];
