import { config } from "config";
import OneSignal from "react-onesignal";
import { log } from "../utils/logger.util";
import Notification from "assets/audio/Notification.mp3";

const url = config.APP_URL;
const audio = new Audio(Notification);
const appId = config.ONE_SIGNAL_APP_ID;

const OneSignalService = {
	init: async () => {
		if (config.NODE_ENV !== "production") return;

		await OneSignal.init({ appId });
		await OneSignal.setDefaultNotificationUrl(url);
		log("one signal init");

		OneSignal.on("notificationDisplay", () => {
			OneSignalService.playSound();
			log("one signal notificationDisplay");
		});
	},

	connect: async (id: string) => {
		if (config.NODE_ENV !== "production") return;

		await OneSignal.setExternalUserId(id);
		log("one signal connected");
	},

	disconnect: async () => {
		if (config.NODE_ENV !== "production") return;

		await OneSignal.removeExternalUserId();
		log("one signal disconnected");
	},

	playSound: () => {
		try {
			log("playing sound");
			audio.play();
			log("sound played");
		} catch (err) {
			log({ err });
		}
	},
};

export default OneSignalService;
