import {
  EventLoadingPayload,
  EventState,
  SetEventsUpdatePayload,
  SetEventsPayload,
  SetEventPayload,
} from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialEvent = {
  events: [],
  loading: true,
};

const initialState: EventState = {
  tab: 0,
  event: null,
  loading: true,
  open: initialEvent,
  close: initialEvent,
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoading: (state, action: PayloadAction<EventLoadingPayload>) => {
      const { type, loading } = action.payload;
      state[type].loading = loading;
    },
    setEvent: (state, action) => {
      state.event = action.payload;
    },
    setEvents: (state, action: PayloadAction<SetEventsPayload>) => {
      const { type, events } = action.payload;
      state[type].events = events;
    },
    deleteEventById: (state, action: PayloadAction<SetEventsUpdatePayload>) => {
      const { type, id } = action.payload;
      state[type].events = state[type].events.filter(({ _id }) => id !== _id);
    },
    updateEvent: (state, action: PayloadAction<SetEventPayload>) => {
      const { type, event } = action.payload;

      if (!event?._id) return;

      state[type].events = state[type].events.filter(
        ({ _id }) => _id !== event._id
      );
    },
  },
});

const eventReducer = eventSlice.reducer;

export const eventActions = eventSlice.actions;
export default eventReducer;
