import { date, required } from "utils/validate.util";
import DatePickerRedux from "components/molecules/DatePickerRedux";
import RadioGroupRedux from "components/molecules/RadioGroupRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import { DateTimePicker } from "@mui/lab";
import InputRedux from "components/molecules/InputRedux";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import DateTimePickerRedux from "components/molecules/DateTimePickerRedux";
import TimePickerRedux from "components/molecules/TimePickerRedux";

export { default } from "./ShiftForm";

export const fields: ReduxFormField[] = [
  {
    name: "everyday",
    label: "Everyday",
    component: CheckboxRedux,
  },
  {
    name: "start_date",
    label: "Start Date",
    validate: [date, required],
    component: DatePickerRedux,
    cellProps: { md: 6 },
    DatePickerProps: {
      onChange: () => {},
      minDate: new Date(),
      InputFieldProps: {},
      value: { date: "", error: false },
    },
  },
  {
    name: "start_time",
    label: "Start Time",
    validate: [date, required],
    component: TimePickerRedux,
    cellProps: { md: 6 },
    TimePickerProps: {
      onChange: () => {},
      InputFieldProps: {},
      value: { date: "", error: false },
    },
  },
  {
    name: "end_date",
    label: "End Date",
    cellProps: { md: 6 },
    validate: [date, required],
    component: DatePickerRedux,
    DatePickerProps: {
      onChange: () => {},
      minDate: new Date(),
      InputFieldProps: {},
      value: { date: "", error: false },
    },
  },

  {
    name: "end_time",
    label: "End Time",
    validate: [date, required],
    cellProps: { md: 6 },
    component: TimePickerRedux,
    TimePickerProps: {
      onChange: () => {},
      InputFieldProps: {},
      value: { date: "", error: false },
    },
  },
  {
    name: "reason",
    label: "Reason",
    component: InputRedux,
  },
];
