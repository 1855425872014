import { Container } from "@mui/material";
import Tabs from "components/templates/Tabs";
import Banner from "components/templates/Banner";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Button from "components/atoms/Button";
import { useNavigate } from "react-router-dom";
import { customerInvoiceActions } from "redux/slices/customerInvoice/customerInvoiceSlice";
import CustomerOpenInvoicesList from "./CustomerOpenInvoicesList";
import CustomerClosedInvoicesList from "./CustomerClosedInvoicesList";
import { useEffect } from "react";
import InvoiceService from "services/invoice.service";
import BlockComponent from "components/organisms/BlockComponent/BlockComponent";

export default function NewInvoices() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const tab = useAppSelector((state) => state.customerInvoices.tab);


   useEffect(() => {
     InvoiceService.getcustomerInvoices(tab===0?false:true);
   }, [tab]);

  return (
    <div>
      <Container maxWidth="lg">
        <Banner heading="Customer Invoices">
          <BlockComponent permission={"invoice:write"}>
          <Button onClick={() => navigate("/add-invoices")} variant="contained">
            Create Invoice
          </Button>
          </BlockComponent>

        </Banner>
        <Tabs
          value={tab}
          onChange={(tab) => dispatch(customerInvoiceActions.setTab(tab))}
          tabs={[
            {
              label: "Open",
              element: <CustomerOpenInvoicesList />,
            },
            {
              label: "Closed",
              element: <CustomerClosedInvoicesList />,
            },
          ]}
        />
      </Container>
    </div>
  );
}



