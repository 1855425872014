import { TimeZoneState } from ".";
import { config } from "config";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";


const initialState: TimeZoneState = {
  loading: false,
  timeZone: {},
}

export const timeZoneSlice = createSlice({
  name: "timeZone",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTimeZone: (state, action) => {
		const { timeZoneName,timeZoneId } = action.payload;
      state.timeZone = {timeZoneName,timeZoneId};
    },

  },
});

const timeZoneReducer = timeZoneSlice.reducer;

export const timeZoneActions = timeZoneSlice.actions;
export default timeZoneReducer;
