import http from "./http.service";
import SocketService from "./socket.service";
import Promisable from "./promisable.service";
import { authActions } from "redux/slices/auth";
import OneSignalService from "./onesignal.service";
import { getAppDispatch } from "utils/dispatch.util";
import { NavigateFunction } from "react-router-dom";
import { change } from "redux-form";

const AuthService = {
	login: async (data: any) => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));

		const [success, error]: any = await Promisable.asPromise(
			http.post(`/login`, data)
		);

		if (success) {
			const { admin, token } = success.data.data;
			localStorage.setItem("token", `Bearer ${token}`);
			dispatch?.(authActions.setUser(admin));

			OneSignalService.connect(admin._id);
			SocketService.connect(admin);
		}

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},
	forgotPassword: async (data: any, navigate?: NavigateFunction) => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));

		const [success, error]: any = await Promisable.asPromise(
			http.post(`/forgotPassword`, data)
		);

		if (success) {
			const { expiry_time } = success.data.data;

			localStorage.setItem("email", data.email);
			dispatch?.(change("LoginForm", "expiry_time", expiry_time));
			navigate?.("/password-otp");
		} else navigate?.("/forgot-password");

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},
	checkExpiry: async (data: any, navigate?: NavigateFunction) => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));

		const [success, error]: any = await Promisable.asPromise(
			http.post(`/check-expiry`, data)
		);

		if (success) {
			const { expiry_time } = success.data.data;

			localStorage.setItem("email", data.email);
			dispatch?.(change("LoginForm", "expiry_time", expiry_time));
			navigate?.("/password-otp");
		} else navigate?.("/forgot-password");

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},

	verifyOTP: async (data: any, navigate?: NavigateFunction) => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));

		const [success, error]: any = await Promisable.asPromise(
			http.post(`/verifyOTP`, data)
		);

		if (success) navigate?.("/reset-password");

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},

	resetPassword: async (data: any, navigate?: NavigateFunction) => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));

		const [success, error]: any = await Promisable.asPromise(
			http.post(`/resetPassword`, data)
		);

		if (success) {
			localStorage.removeItem("email");
			navigate?.("/login");
		}

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},
	logout: () => {
		const dispatch = getAppDispatch();

		SocketService.disconnect();
		OneSignalService.disconnect();

		localStorage.removeItem("token");

		dispatch?.(authActions.logout());
		dispatch?.(authActions.setUser(null));
	},
};
export default AuthService;
