import { useEffect } from "react";
import { useAppSelector } from "redux/hooks";
import SelectRedux from "components/molecules/SelectRedux";
import CompanyService from "services/company.service";

export default function SelectRole(props: any) {
  const paymentOptions = useAppSelector(
    (state) => state.company.paymentOptions
  );


  useEffect(() => {
    CompanyService.getPaymentMethods()
  }, []);

  return <SelectRedux {...props} options={paymentOptions} />;
}
