import { useAppSelector } from "redux/hooks";
import ShiftForm from "./ShiftForm";
import SmartServices from "services/smart.service";
import moment from "moment";
import "moment-timezone";
import { useParams } from "react-router-dom";
import UpdateShift from "./UpdateShift";

export default function AddShift() {
  const { shift, zone,update } = useAppSelector((state) => state.modal.data);
  const myvlues= useAppSelector((state) => state.form?.ShiftForm?.values);

const {driver, driver_id,main_id}:any=shift||{}

  const setTime = (value: any) => {
    const date = new Date(value);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  };

  const handleSubmit = async (values: any) => {
    let data: any = {};

    if (values.start_date.date)
      data.start_date = moment.parseZone(values.start_date.date, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (zz)').format("YYYY-MM-DD");
    if (values.end_date.date) {
      data.end_date = moment.parseZone(values.end_date.date, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (zz)').format("YYYY-MM-DD");
    } else {
      data.end_date = moment.parseZone(values.start_date.date, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (zz)').format("YYYY-MM-DD");
    }
    if (values.start_time.date)
      data.start_time = setTime(values.start_time.date);
    if (values.end_time.date) data.end_time = setTime(values.end_time.date);
    if (values.start_time.date && values.end_time.date) {
      let start_time = moment(values.start_time.date);
      let end_time = moment(values.end_time.date);
      let duration = moment.duration(end_time.diff(start_time));
      data.duration = duration.asMinutes();
    }
     data.everyday = values.everyday||false;
    if (values.reason) data.reason = values.reason;

    if(update&&main_id) SmartServices.updateShift(main_id,{ ...data,driver_id,zone ,slot_id:shift.slot_id},zone)
    else SmartServices.addShift({ ...data,driver_id,zone, }, zone);

  };

  return (
    <div>
      <h3>{update?"Update Shift":"Add Shift to"} {driver}</h3>
      <ShiftForm onSubmit={handleSubmit} />
      {update&&<UpdateShift/>}
    </div>
  );
}
