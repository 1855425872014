import { useEffect } from "react";
import Button from "components/atoms/Button";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import { Table, TableRow, TableBody, TableHead, TableContainer, Box, } from "@mui/material";
import { MODAL, modalActions } from "redux/slices/modal";
import DynamicService from "services/dynamics.service";
import BlockComponent from "components/organisms/BlockComponent/BlockComponent";


export default function DynamicCarMovement({ type }: any) {
    const dispatch = useAppDispatch();
    const dynamicCarMovements = useAppSelector((state) => state.dynamics.dynamicCarMovements);
    const loading = useAppSelector((state) => state.dynamics.loading);

    function capitalizeFirstLetter(str: any) {
        return str[0].toUpperCase() + str.slice(1);
    }

    useEffect(() => {
        DynamicService.getDynamicCarMovement(type);
    }, []);

    return (
        <>
            <Box sx={{
                display: "flex",
                justifyContent: "flex-end"
            }}>
                <Button variant="contained"
                    sx={{ mb: 2 }}
                    onClick={() =>
                        dispatch(
                            modalActions.openModal({
                                type: MODAL.DYNAMIC_CAR_MOVEMENT,
                                data: { type },
                                width: "500px",
                            })
                        )
                    }

                >
                    {type === "movementType" ? "Add Movement Type" : "Add Platform type"}

                </Button>
            </Box>
            <TableContainer>
                <Table
                    aria-label="customized table"
                    sx={{
                        minWidth: "100%",
                        borderSpacing: "0 10px",
                        borderBottomWidth: "0px",
                        borderCollapse: "separate",
                    }}>
                    <TableLoadingWrapper
                        coloumns={4}
                        loading={loading}
                        length={dynamicCarMovements?.length}
                        message="No Dynamics are available">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                <BlockComponent permission={"dynamics:write"}>
                                    <StyledTableCell align="right">Action</StyledTableCell>
                                </BlockComponent>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dynamicCarMovements.map((dynamic: any, i: number) => (
                                <StyledTableRow key={i + 1}>
                                    <StyledTableCell>
                                        {capitalizeFirstLetter(dynamic?.name)}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {dynamic.is_active ? "Active" : "In-active"}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <BlockComponent permission={"dynamics:write"}>
                                            <Button
                                                size="small"
                                                variant="text"
                                                onClick={() =>
                                                    dispatch(
                                                        modalActions.openModal({
                                                            type: MODAL.DYNAMIC_CAR_MOVEMENT,
                                                            data: {
                                                                id: dynamic?._id,
                                                                info: dynamic,
                                                                type,
                                                            },
                                                            width: "500px",
                                                        })
                                                    )
                                                }>
                                                Edit
                                            </Button>
                                        </BlockComponent>

                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </TableLoadingWrapper>
                </Table>
            </TableContainer>
        </>

    );
}
