import React, { useEffect } from 'react'
import { change } from 'redux-form';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import DateService from 'utils/date.util';

const UpdateShift = () => {
    const form = "ShiftForm";
	const dispatch = useAppDispatch();
    const {shift} = useAppSelector((state) => state.modal.data);


	useEffect(() => {
		if(!shift)return
		dispatch(change(form, "shift_id", shift?.shift_id));
		dispatch(change(form, "reason", shift?.title));
		// dispatch(change(form, "everyday", shift?.everyday));
        dispatch(change(form, "start_date", {date:new Date(shift?.s_date),error:false}));
        dispatch(change(form, "end_date", {date:new Date(shift?.e_date),error:false}));
        dispatch(change(form, "start_time", {date:new Date(DateService.getTimeToDate(shift?.s_time)),error:false}));
        dispatch(change(form, "end_time", {date:new Date(DateService.getTimeToDate(shift?.e_time)),error:false}));
        
	}, [shift,dispatch]);

	return null;
}

export default UpdateShift