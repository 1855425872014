// import http from "./http.service";
import Promisable from "./promisable.service";
import { getAppDispatch } from "utils/dispatch.util";
import { timeZoneActions } from "redux/slices/timeZone";
import {config} from 'config'
import ToasterService from "utils/toaster.util";




const url = `https://maps.googleapis.com/maps/api/timezone/json?location=`; // reported-problems

const timeZoneService = {

    getTimeZone: async (lat: Number, lng: Number) => {
    const dispatch = getAppDispatch();
        const timestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds

        const [success, error]: any = await Promisable.asPromise(
            fetch(`${url}${lat},${lng}&timestamp=${timestamp}&key=${config.GOOGLE_MAPS_API_KEY}`)
        );
        const result=await success.json()
        if (result) {
            if(result?.errorMessage){
                ToasterService.showError(`${result?.errorMessage}`);
                return
            }
            const {timeZoneName,timeZoneId}=result
            dispatch?.(timeZoneActions.setTimeZone({timeZoneName,timeZoneId}));
          }
          dispatch?.(timeZoneActions.setLoading(false));
        return [success, error];

    }
};

export default timeZoneService;