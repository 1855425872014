import { useEffect } from "react";
import Button from "components/atoms/Button";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import { Table, TableRow, TableBody, TableHead, TableContainer, Tooltip, } from "@mui/material";
import { MODAL, modalActions } from "redux/slices/modal";
import DynamicService from "services/dynamics.service";
import InfoIcon from "@mui/icons-material/Info";
import BlockComponent from "components/organisms/BlockComponent/BlockComponent";


export default function CrossCountryPrice() {
    const dispatch = useAppDispatch();
    const countryPrices = useAppSelector((state) => state.dynamics.countryPrices);
    const loading = useAppSelector((state) => state.dynamics.loading);

    function capitalizeFirstLetter(str: any) {
        return str[0].toUpperCase() + str.slice(1);
    }

    useEffect(() => {
        DynamicService.getCrossCountryPrices();
    }, []);

    return (
        <TableContainer>
            <Table
                aria-label="customized table"
                sx={{
                    minWidth: "100%",
                    borderSpacing: "0 10px",
                    borderBottomWidth: "0px",
                    borderCollapse: "separate",
                }}>
                <TableLoadingWrapper
                    coloumns={4}
                    loading={loading}
                    length={countryPrices?.length}
                    message="No Dynamics are available">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Net Prices</StyledTableCell>
                            <BlockComponent permission={"dynamics:write"}>
                                <StyledTableCell align="center">Action</StyledTableCell>
                            </BlockComponent>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {countryPrices.map((price: any, i: number) => (
                            <StyledTableRow key={i + 1}>
                                <StyledTableCell>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}>
                                        <div style={{ width: "150px" }}>

                                            {capitalizeFirstLetter(price?.text)}
                                        </div>
                                        <Tooltip arrow title={price.description}>
                                            <InfoIcon style={{ color: "grey", fontSize: "20px", cursor: 'pointer' }} />
                                        </Tooltip>
                                    </div>
                                </StyledTableCell>
                                <StyledTableCell>{price?.price}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <BlockComponent permission={"dynamics:write"}>

                                        <Button
                                            size="small"
                                            variant="text"
                                            onClick={() =>
                                                dispatch(
                                                    modalActions.openModal({
                                                        type: MODAL.UPDATE_CROSS_COUNTRY_PRICE,
                                                        data: {
                                                            id: price?._id,
                                                            info: price
                                                        },
                                                        width: "500px",
                                                    })
                                                )
                                            }>
                                            Edit
                                        </Button>
                                    </BlockComponent>

                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </TableLoadingWrapper>
            </Table>
        </TableContainer>
    );
}
