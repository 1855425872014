import { ReduxFormField } from "components/molecules/ReduxFormFields";
import { required } from "utils/validate.util";
import SelectRedux from "components/molecules/SelectRedux";

export { default } from "./UpdatePaymentStatusForm";


export const fields: ReduxFormField[] = [
  

  {
    name: "payment_status",
    label: "Status",
    validate: [required],
    component: SelectRedux,
    cellProps: { md: 12 },
    SelectProps: {
      options: [
        { value: "paid", label: "Paid" },
        { value: "unpaid", label: "Not Paid" },
        { value: "refund", label: "Refund" },
      ],
    },
  },
];
