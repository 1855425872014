import { ZoneState } from ".";
import { SelectOption } from "components/atoms/Select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ZoneState = {
	zones: [],
	zone: null,
	setZone_id:"",
	loading: true,
	zoneOptions:[]

};

export const zoneSlice = createSlice({
	name: "zone",
	initialState,
	reducers: {
		clear: (state) => {
			state.zoneOptions = [];
			state.zone=null
			state.setZone_id=""
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		addZone: (state, action) => {
			state.zones.unshift(action.payload);
		},
		updateZone: (state, action) => {
			const { id, zone } = action.payload;
			
			state.zones.every(({ _id }, i) => {
				if (id === _id) {
					state.zones[i] = zone;
					return false;
				}
				return true;
			});
		},
		setZone: (state, action) => {
			state.zone = action.payload;
		},
		setZone_id: (state, action) => {
			state.setZone_id = action.payload;
		},
		setZones: (state, action) => {
			const zones = action.payload;
			let options: SelectOption[] = [];
			zones.forEach(({ _id, name }: any) =>
				options.push({ value: _id, label: name })
			);
			state.zones = zones;
			state.zoneOptions = options;
		},
	},
});

const zoneReducer = zoneSlice.reducer;

export const zoneActions = zoneSlice.actions;
export default zoneReducer;
