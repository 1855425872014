import { PayRollState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "config";
const default_page_size = config.PAGE_SIZE;

const initialState: PayRollState = {
  employees: [],
  loading: true,
  count: 0,
  refresh: 0,
  current_filters: {},
  refreshLoader: false,
  filters: { page: 1, page_size: default_page_size },
};

export const PayRollSlice = createSlice({
  name: "payRoll",
  initialState,
  reducers: {
    setEmployees: (state, action) => {
      const { attendance, count } = action.payload;
      state.employees = attendance;
      state.count = count;
      state.refreshLoader = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const payRollReducer = PayRollSlice.reducer;

export const payRollActions = PayRollSlice.actions;
export default payRollReducer;
