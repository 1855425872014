import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import DateService from "utils/date.util";

export default function UpdateAvailabilityForm() {
  const form = "AddAvailabilityForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const availability = useAppSelector(
    (state) => state.availability.availability
  );

  useEffect(() => {
    if (!availability || availability === "") return;
    const { _id, zone_id, holiday_interval } = availability;

    dispatch(change(form, "zone_id", zone_id?._id ? zone_id?._id : ""));
    dispatch(change(form, "class_id", "64f1f0aba1a2b38756d7ac61"));
    dispatch(
      change(form, "start_date", {
        error: false,
        date: DateService.getDateTimeString(holiday_interval?.start_time),
      })
    );
    dispatch(
      change(form, "end_date", {
        error: false,
        date: DateService.getDateTimeString(holiday_interval?.end_time),
      })
    );
    setTimeout(() => {
      dispatch(change(form, "class_id", _id));
    }, 500);
  }, [availability, navigate, dispatch]);

  return null;
}
