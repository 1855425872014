import CircleLoader from "components/atoms/CircleLoader";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import { useAppSelector } from "redux/hooks";
import UserService from "services/user.service";
import AddUserForm from "./AddUserForm";
import GoBack from "components/atoms/GoBack";
import Banner from "components/templates/Banner";

export default function UpdateUserProfile() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const user = useAppSelector((state) => state.user.user);

 

  const handleSubmit = async (values: any) => {
    let data = { ...values };

    if (data.phone?.data) {
      data.phone = `+${values.phone.value}`;
      data.country = values.phone.data.countryCode;
      data.country_code = `+${values.phone.data.dialCode}`;
    } else {
      data.country = "false";
      data.phone = values.phone.value;
      data.country_code = user.country_code;
    }

    setLoading(true);
    await UserService.addUser(data,navigate);
    setLoading(false);
  };

    return (
      <Container>
        <GoBack path="/users" title="Back to Users" />
        <Banner heading={`Add User`} />
        <div className="form" style={{ position: "relative" }}>
          {loading && <CircleLoader />}
          <AddUserForm onSubmit={handleSubmit} />
        </div>
      </Container>
    );
}
