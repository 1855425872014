import {
  Stack,
  Table,
  TableRow,
  TableHead,
  TableBody,
  IconButton,
  TableContainer,
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import {
  StyledTableCell,
  StyledTableRow,
} from "components/templates/Tables";
import SwitchButton from "components/atoms/SwitchButton";
import { MODAL, modalActions } from "redux/slices/modal";
import DateService from "utils/date.util";
import SubscriptionService from "services/subscription.service";
import InvoiceService from "services/invoice.service";
import BlockComponent from "components/organisms/BlockComponent/BlockComponent";




export default function CustomerClosedInvoicesList() {
  const closedInvoice = useAppSelector(
    (state) => state.customerInvoices.customerClosedInvoice
  );
  const loading = useAppSelector(
    (state) => state.formLoader.loading
  );

  const dispatch = useAppDispatch();


  return (
    <>
      <TableContainer>
        <Table
          sx={{
            minWidth: "100%",
            borderCollapse: "separate",
            borderBottomWidth: "0px",
            borderSpacing: "0 10px",
          }}
          aria-label="customized table">
          <TableLoadingWrapper
            coloumns={6}
            loading={loading}
            message="No Invoices Yet!"
            length={closedInvoice.length}>
            <TableHead>
              <TableRow>
                <StyledTableCell>ID</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Customer</StyledTableCell>
                <StyledTableCell>PickUp</StyledTableCell>
                <BlockComponent permission={"invoice:write"}>

                  <StyledTableCell>Status</StyledTableCell>
                </BlockComponent>
                <StyledTableCell>Price</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {closedInvoice.map((invoice, i) => (
                <StyledTableRow key={i + 1}>
                  <StyledTableCell>{invoice.invoice_number}</StyledTableCell>
                  <StyledTableCell>
                    {DateService.getFormattedDate(invoice.createdAt)}
                  </StyledTableCell>
                  <StyledTableCell>{invoice.customer_name}</StyledTableCell>

                  <StyledTableCell>
                    {invoice.pickUp} <br />
                    {invoice.destination ? <b> to </b> : <b>duraiton </b>}
                    {invoice.destination
                      ? invoice.destination
                      : `${invoice.duration} hours`}
                  </StyledTableCell>
                  <BlockComponent permission={"invoice:write"}>
                    <StyledTableCell>
                      <SwitchButton
                        checked={invoice.is_payed}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          const status = checked ? "active" : "inactive";

                          SubscriptionService.subscribe(() => {
                            InvoiceService.updateCustomerInvoiceStatus(
                              invoice._id,
                              invoice.is_payed
                            );
                          });
                          dispatch(
                            modalActions.openModal({
                              width: "500px",
                              type: MODAL.CONFIRMATION_FORM,
                              data: {
                                status,
                                id: "fs",
                                heading: "Update Invoice Status",
                                message: `Do you really want to Change Invoice status?`,
                              },
                            })
                          );
                        }}
                      />
                    </StyledTableCell>
                  </BlockComponent >

                  <StyledTableCell>
                    {invoice.total_price >= 0 ? invoice.total_price.toFixed(2) : invoice.total_price} SAR
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center">
                      {/* <IconButton color="primary" aria-label="Notes">
                        <NoteAltIcon />
                      </IconButton> */}
                      <IconButton color="primary" aria-label="Download">
                        <a
                          style={{ textDecoration: "none", color: "inherit" }}
                          href={invoice.invoice_link}
                          target="_blank"
                          rel="noreferrer">
                          <GetAppIcon />
                        </a>
                      </IconButton>
                    </Stack>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TableLoadingWrapper>
        </Table>
      </TableContainer>
    </>
  );
}
