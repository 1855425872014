import { ISmart } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "config";
const default_page_size = config.PAGE_SIZE;

const initialState: ISmart = {
  count: 0,
  list: [],
  booking: null,
  timeLine: {},
  loading: true,
  current_filters: {},
  filters: { page: 1, page_size: default_page_size },
  refresh: 0,
  reset: 0,
  refreshLoader: false,
  stats: false,
  hotRefresh: true,
  timeHotRefresh: true,
  shift: [],
};


export const smartSlice = createSlice({
  name: "smart",
  initialState,
  reducers: {
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },

    setPage: (state, action: PayloadAction<any>) => {
      const {  page } = action.payload;
      state.filters.page = page;
      state.reset += 1;
      state.refreshLoader = true;
    },

    resetPage: (state, action: PayloadAction<any>) => {
      state.filters.page = 1;
    },
    setFilters: (state, action: PayloadAction<any>) => {
      const {  data } = action.payload;
      state.filters = data;
      state.refreshLoader = true;
    },
    resetFilters: (state: any) => {
      state.reset += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
    setCurrentFilters: (state, action: PayloadAction<any>) => {
      const { data } = action.payload;
      state.current_filters = data;
    },
    setList: (state, action) => {
      const {  list, count, stats } = action.payload;
      state.list = list;
      state.count = count;
      state.stats = stats;
      state.refreshLoader = false;
    },
    setBooking: (state, action) => {
      state.booking = action.payload;
    },
    setTimeLine: (state, action) => {
      const {  shift, ...rest } = action.payload;
      state.timeLine = rest;
      state.refreshLoader = false;
      state.hotRefresh = false;
      state.shift = shift;
    },
    clearTimeLine: (state, action) => {
      state.timeLine = [];
      state.refreshLoader = false;
      state.shift = [];
    },
    addShift: (state, action) => {
      const {  shift } = action.payload;
      state.shift.push(shift);
    },
    updateShift: (state, action) => {
      const {  shift } = action.payload;
      if (!shift?._id) return;
      state.shift.every(({ _id }: any, i: number) => {
        if (_id === shift._id) {
          state.shift[i] = shift;
          return false;
        }
        return true;
      });
    },
    cancelShift: (state, action) => {
      const {  shiftId, shift } = action.payload;
      if (shift) {
        state.shift.every(({ _id }: any, i: number) => {
          if (_id === shift._id) {
            state.shift[i] = shift;
            return false;
          }
          return true;
        });
      } else {
        state.shift = state.shift.filter(
          ({ _id }: any) => {
            if (shiftId && shiftId === _id) {
              return false;
            }
            return true;
          }
        );
      }
    },
    setStats: (state, action) => {
      state.stats = action.payload;
      state.refreshLoader = false;
    },
    setLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
      state.refreshLoader = action.payload;
      state.hotRefresh = false;
    },
  },
});

const smartReducer = smartSlice.reducer;

export const smartActions = smartSlice.actions;
export default smartReducer;
