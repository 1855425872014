import AvailabilityList from "./AvailabilityList";
import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import Button from "components/atoms/Button";
import { useAppDispatch } from "redux/hooks";
import Banner from "components/templates/Banner";
import AddAvailability from "pages/AddAvailability";
import BlockComponent from "components/organisms/BlockComponent/BlockComponent";

export default function Availability() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div>
      <Container maxWidth="lg">
        <Banner heading="Availability"></Banner>
        <BlockComponent permission={"availability:write"}>
          <AddAvailability />
        </BlockComponent>
        <AvailabilityList />
      </Container>
    </div>
  );
}
