import { digitFixer } from "./digitfixer.util";

export const PriceService = {
    brutto_to_net: (price: number, tax: number) => {
        return digitFixer(price / (1 + tax / 100));
    },
    brutto_exclude_tax: (price: number, tax: number) => {
        let brutto_to_net = digitFixer(price / (1 + tax / 100));
        return digitFixer(price - brutto_to_net);
    },
    total_price: (price_details: any) => {
        let price =
            price_details.brutto_price -
            (price_details.coupon.value || 0) -
            (price_details.referral_balance || 0) -
            (price_details.mc_points_balance || 0);
        return digitFixer(price);
    },
    get_taxable_value: (price: number, tax: number) => {
        return digitFixer(price * (tax / 100));
    },
    get_netto_from_brutto: (price_details: any, tax: number) => {
        let price = price_details.brutto_price;
        // console.log("price",price)
        // if (price_details?.coupon.value>0) {
        //     price= - price_details?.coupon.value
        // }
        // console.log("price1",price)
        return digitFixer(price / (1 + tax / 100));
    },
    get_brutto_tax: (price:number, tax = 15) => {
        const net = PriceService.brutto_to_net(price, tax)
        return digitFixer(net * (tax / 100));
    }
}