import { fields } from ".";
import { Stack } from "@mui/material";
import { change, reduxForm } from "redux-form";
import Button from "components/atoms/Button";
import { modalActions } from "redux/slices/modal";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import { useEffect } from "react";

function UpdateDynamicsForm({ handleSubmit }: any) {
  const dispatch = useAppDispatch();

  const form = "UpdateDynamicsForm";
  const dynamic = useAppSelector((state)=>state?.modal?.data?.info)



	useEffect(() => {
    if (!dynamic) return;
    const { user,company } = dynamic?.value;
    dispatch(change(form, "user", user));
    dispatch(change(form, "company", company));
    dispatch(change(form, "description", dynamic?.description));
  }, [dynamic, dispatch]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ReduxFormFields fields={fields} />
        <br />
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            onClick={() => dispatch(modalActions.closeModal())}>
            Cancel
          </Button>
          <Button type="submit" disableElevation variant="contained">
            Save
          </Button>
        </Stack>
      </form>
    </div>
  );
}

export default reduxForm({ form: "UpdateDynamicsForm" })(UpdateDynamicsForm);
