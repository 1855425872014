import { ReduxFormField } from "components/molecules/ReduxFormFields";
import DateTimePickerRedux from "components/molecules/DateTimePickerRedux";

export { default } from "./UpdatePayRollForm";

export const fields: ReduxFormField[] = [
  {
    name: "checkIn",
    label: "Check-In Time",
    component: DateTimePickerRedux,
    // validate: [timeRange],

    cellProps: { sm: 12 },
  },
  {
    label: "Check-Out Time",
    name: "checkOut",
    component: DateTimePickerRedux,
    // validate: [timeRange],
    cellProps: { sm: 12 },
  },
];
