import ConfirmRefundFrom from "./ConfirmRefundFrom";
import VerficationIdentityService from "services/verfication.identity.service";

export default function ConfirmRefund() {
  const handleSubmit = async (values: any) => {
    const data = {
      email: values.email,
      password: values.password,
      requestToken: VerficationIdentityService.requestToken,
    };
    VerficationIdentityService.makeVerficationCall(data);
  };

  return (
    <div>
      <h3>Enter you email or password to complete refund</h3>
      <ConfirmRefundFrom onSubmit={handleSubmit} />
    </div>
  );
}
