import { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import AuthService from "services/auth.service";
import ToasterService from "utils/toaster.util";

export default function BlockComponent({ permission, children }:any) {
  const [block, setBlock] = useState(true);
  const permissions = useAppSelector((state) => state.auth?.user?.role_id?.permissions);

  useEffect(() => {    
    if(permissions.length>0){
			let permissionCheck = permissions.includes(permission)
      
      if(permissionCheck){
          setBlock(false);
      }
      
    }else{
      setBlock(true);
      ToasterService.showError("Permission denied! Contact your admin.")
    }
  }, [ permission]);

  return <>{!block && children}</>;
}
