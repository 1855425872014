import { email, password, required } from "utils/validate.util";
import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import SelectRedux from "components/molecules/SelectRedux";
import PhoneInputRedux from "components/molecules/PhoneInputRedux";

export { default } from "./AddUserForm";

export const fields: ReduxFormField[] = [
  {
    name: "title",
    label: "Title",
    validate: [required],
    component: SelectRedux,
    SelectProps: {
      options: [
        { value: "Mr.", label: "Mr." },
        { value: "Ms.", label: "Ms." },
      ],
    },
    cellProps: { lg: 6 },
  },
  {
    name: "first_name",
    label: "First Name",
    validate: [required],
    component: InputRedux,
    cellProps: { lg: 6 },
  },
  {
    name: "last_name",
    label: "Last Name",
    validate: [required],
    component: InputRedux,
    cellProps: { lg: 6 },
  },
  {
    name: "email",
    label: "Email",
    validate: [required, email],
    component: InputRedux,
    cellProps: { lg: 6 },
    InputProps: {
      type: "email",
    },
  },
  {
    name: "phone",
    label: "Phone",
    validate: [required],
    component: PhoneInputRedux,
    cellProps: { lg: 6 },
  },
  {
    name: "password",
    label: "Password",
    validate: [required, password],
    component: InputRedux,
    cellProps: { lg: 6 },
  },
];
