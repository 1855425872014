import {
  CompanyRequestState,
  SetCompanyRequestsPayload,
  CompanyRequestLoadingPayload,
  SetCompanyRequestPayload,
} from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialCompanyRequest = {
  companyRequests: [],
  loading: true,
};
const initialState: CompanyRequestState = {
  tab: 0,
  companyRequest: null,
  loading: false,
  open: initialCompanyRequest,
  close: initialCompanyRequest,
};

export const companyRequestSlice = createSlice({
  name: "companyRequest",
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoading: (
      state,
      action: PayloadAction<CompanyRequestLoadingPayload>
    ) => {
      const { type, loading } = action.payload;
      state[type].loading = loading;
    },
    setCompanyRequests: (
      state,
      action: PayloadAction<SetCompanyRequestsPayload>
    ) => {
      const { type, companyRequests } = action.payload;

      state[type].companyRequests = companyRequests;
    },
    updateCompanyRequest: (
      state,
      action: PayloadAction<SetCompanyRequestPayload>
    ) => {
      const { type, companyRequest } = action.payload;

      if (!companyRequest?._id) return;

      state[type].companyRequests = state[type].companyRequests.filter(
        ({ _id }) => _id !== companyRequest._id
      );
    },
  },
});

const companyRequestReducer = companyRequestSlice.reducer;

export const companyRequestActions = companyRequestSlice.actions;
export default companyRequestReducer;
