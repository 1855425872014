import { CommissionState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: CommissionState = {
	loading: true,
	commission: null,
};

export const commissionSlice = createSlice({
	name: "commission",
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setCommission: (state, action) => {
			state.commission = action.payload;
		},
	},
});

const commissionReducer = commissionSlice.reducer;

export const commissionActions = commissionSlice.actions;
export default commissionReducer;
