import { useEffect } from "react";
import { change } from "redux-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import PaymentService from "services/payment.service";
import UpdatePaymentStatusForm from "./UpdatePaymentStatusForm";

export default function UpdatePaymentStatus() {
 	const id = useAppSelector((state) => state.booking.booking?._id);
const booking = useAppSelector((state) => state.booking.booking);
  const dispatch = useAppDispatch();

  const form = "UpdatePaymentStatusForm";

    const status = useAppSelector(
      (state) => state.form?.[form]?.values?.payment_status
    );



    
    useEffect(() => {
      status !== "paid" && dispatch(change(form, "payment_method", ""));
      
    },[dispatch, status])
    
    
    useEffect(() => {
      dispatch(change(form, "payment_status", booking?.payment_status));
      dispatch(change(form, "payment_method", booking?.payment_method));
      
    }, [booking?.payment_method, booking?.payment_status, dispatch])
  
 

  const handleSubmit = (values: any) => {

    PaymentService.updatePaymentStatus( id,values );
   
  };

  return (
    <div>
      <h3>Update Payment Status</h3>
      <UpdatePaymentStatusForm onSubmit={handleSubmit} />
    </div>
  );
}
