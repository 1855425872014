import { useAppSelector } from "redux/hooks";
import ImageService from "services/image.service";
import PaymentService from "services/payment.service";
import AddAttachmentForm from "./AddAttachmentForm";

export default function AddAttachment() {
  const id = useAppSelector((state) => state.booking.booking?._id);

  const handleSubmit = async (values: any) => {
    const formData = new FormData();
    const { image, title } = values;
    let img = await ImageService.getImageFileFromBlob(image);

    formData.append("image", img);
    formData.append("title", title);


    PaymentService.addAttachment(id, formData);
  };

  return (
    <div>
      <h3>Uppload your Document</h3>
      <AddAttachmentForm onSubmit={handleSubmit} />
    </div>
  );
}
