import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import RoleService from "services/role.service";
import { roleActions } from "redux/slices/role/roleSlice";

export default function UpdateRoleForm({ id }: any) {
	const form = "AddRoleForm";
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const Role = useAppSelector((state) => state.role.role);

	useEffect(() => {
		RoleService.getRole(id);
		return () => {
			dispatch(roleActions.setRole(null));
		};
	}, [id, dispatch]);

	useEffect(() => {
		if (!Role || Role?.data === "Not Found") return;
		const { email, first_name, last_name, role,role_id } = Role;
		dispatch(change(form, "first_name", first_name));
		dispatch(change(form, "last_name", last_name));
		dispatch(change(form, "email", email));
		dispatch(change(form, "role_id", role_id));
	}, [navigate, dispatch, Role]);

	return null;
}
