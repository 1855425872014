import { useParams } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import DynamicService from "services/dynamics.service";
import UpdateDynamicsForm from "./UpdateDynamicsForm";

export default function UpdateDynamics() {
const id= useAppSelector((state)=>state.modal.data.id)


  const handleSubmit = (values: any) => {

   
    let data = {
      value:{
      user: Number(values.user),
        company: Number(values.company)
      },
      description : values.description
    };
   

  DynamicService.updateDynamic(id,data)
  };

  return (
    <div>
      <h3>Update Dynamics Values</h3>
      <UpdateDynamicsForm onSubmit={handleSubmit} />
    </div>
  );
}
