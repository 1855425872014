import { config } from "config";
import { VehicleState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const default_page_size = config.PAGE_SIZE;
const initialState: VehicleState = {
	count: 0,
	refresh: 0,
	vehicles: [],
	vehicleOptions: [],
	vehicle: null,
	loading: true,
	current_filters: {},
	refreshLoader: false,
	filters: { page: 1, page_size: default_page_size },
};

export const vehicleSlice = createSlice({
	name: "vehicle",
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		addVehicle: (state, action) => {
			state.vehicles.unshift(action.payload);
		},
		updateVehicle: (state, action) => {
			const { id, vehicle } = action.payload;
			state.vehicles.every(({ _id }, i) => {
				if (id === _id) {
					state.vehicles[i] = vehicle;
					return false;
				}
				return true;
			});
		},
		setVehicle: (state, action) => {
			state.vehicle = action.payload;
		},
		setVehicles: (
			state,
			action: PayloadAction<{ vehicles: any[]; count: number }>
		) => {
			const { count, vehicles } = action.payload;
			let options: SelectOption[] = [];

			vehicles.forEach(({ _id, model, color, license_plate, status }: any) => {
				if (status === "approved") {
					const option = { value: _id, label: `${model} ${license_plate} ${color}` };
					options.push(option);
				}
			});
			state.count = count;
			state.vehicles = vehicles;
			state.refreshLoader = false;
			state.vehicleOptions = options;

		},
		setPage: (state, action: PayloadAction<number>) => {
			state.refresh += 1;
			state.refreshLoader = true;
			state.filters.page = action.payload;
		},
		refresh: (state) => {
			state.refresh += 1;
			state.refreshLoader = true;
		},
		resetPage: (state) => {
			state.filters.page = 1;
		},
		setFilters: (state, action) => {
			state.filters = action.payload;
			state.refreshLoader = true;
		},
		setCurrentFilters: (state, action) => {
			state.current_filters = action.payload;
		},
		resetFilters: (state) => {
			state.refresh += 1;
			state.refreshLoader = true;
			state.filters = initialState.filters;
			state.current_filters = initialState.current_filters;
		},
	},
});

const vehicleReducer = vehicleSlice.reducer;

export const vehicleActions = vehicleSlice.actions;
export default vehicleReducer;
