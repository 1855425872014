import { CurrencyState } from ".";
// import ToasterService from "utils/toaster.util";
// import { SelectOption } from "components/atoms/Select";
import { createSlice } from "@reduxjs/toolkit";

const initialState: CurrencyState = {
  currencies: [],
  currenciesOption: [],
};

export const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    clear: (state) => {
      state.currenciesOption = [];
    },


    setCurrency: (state, action) => {
      const currencies = action.payload;
      let options: any[] = [];

      currencies.forEach(({ sign, name }: any,index:any) => {
        options.push({ value:index, label: name,name:name,sign:sign });
      });
      state.currenciesOption = options;
    },

    setCurrenciesOriginal: (state, action) => {
      const currencies = action.payload;
      // let options: any[] = [];

      // currencies.forEach(({ sign, name }: any,index:any) => {
      //   options.push({ name:name,sign:sign });
      // });
      state.currencies = currencies;
    },
  
  },
});

const currencyReducer = currencySlice.reducer;

export const currencyActions = currencySlice.actions;
export default currencyReducer;
