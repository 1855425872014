import { file, required } from "utils/validate.util";
import FileUploadRedux from "components/molecules/FileUploadRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import TextEditor from "components/atoms/TextEditor";
import InputRedux from "components/molecules/InputRedux";

export { default } from "./BannerPicForm";

export const fields: ReduxFormField[] = [
	{
		name: "media",
		label: "Media",
		validate: [file, required],
		component: FileUploadRedux,
		cellProps: { md: 6 },
		FileUploadProps: {
			maxSize: 5,
			accept: [".jpg", ".jpeg", ".png"],
		},
	},
	{
		name: "title_en",
		label: "Title (EN)",
		validate: [required],
		component: TextEditor,
		cellProps: { md: 12 },
	},
	{
		name: "description_en",
		label: "Description (EN)",
		validate: [required],
		component: TextEditor,
		cellProps: { md: 12 },
	},
	{
		name: "title_ar",
		label: "Title (AR)",
		validate: [required],
		component: TextEditor,
		cellProps: { md: 12 },
	},
	{
		name: "description_ar",
		label: "Description (AR)",
		validate: [required],
		component: TextEditor,
		cellProps: { md: 12 },
	},

];
