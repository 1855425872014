
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomerInvoice } from ".";

const initialState: CustomerInvoice = {
  customerOpenInvoice: [],
  customerClosedInvoice: [],
  loading: false,
  tab: 0,
};

export const customerInvoiceSlice = createSlice({
  name: "companyInvoice",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },

    setCustomerOpenInvoice: (state, action) => {
      state.customerOpenInvoice = action.payload;
    },
    setCustomerClosedInvoice: (state, action) => {
      state.customerClosedInvoice = action.payload;
    },
    filterOpenInvoice: (state, action) => {
      const id = action.payload
      state.customerOpenInvoice = state.customerOpenInvoice.filter(({_id})=>_id!==id)
      
    },
    filterCloseInvoice: (state, action) => {
      const id = action.payload
      state.customerClosedInvoice = state.customerClosedInvoice.filter(
        ({ _id }) => _id !== id
      );
      
    }
  },
});

const customerInvoiceReducer = customerInvoiceSlice.reducer;

export const customerInvoiceActions = customerInvoiceSlice.actions;
export default customerInvoiceReducer;
