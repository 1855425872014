import VerficationIdentityService from "./verfication.identity.service";

export default class Promisable {
  static asPromise(promise: Promise<unknown>) {
    return new Promise((resolve) =>
      promise.then(
        (res: any) => resolve([res, null]),
        (err: any) => {
          const { config, message: msg, response } = err;
          const message = response?.data?.message;
          console.log(message);
          const { code } = response.data;
          if (code == 402) {
            VerficationIdentityService.openVerficationModel(resolve, message);
          } else {
            resolve([null, err]);
          }
        }
      )
    );
  }
}
