import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
} from "@mui/material";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import AvailabilityService from "services/availability.service";
import DateService from "utils/date.util";
import Button from "components/atoms/Button";
import { availabilityActions } from "redux/slices/availability";
import SubscriptionService from "services/subscription.service";
import { MODAL, modalActions } from "redux/slices/modal";
import Chip from "components/atoms/Chip";
import BlockComponent from "components/organisms/BlockComponent/BlockComponent";

export default function AvailabilityList() {
  const dispatch = useAppDispatch();
  const availabilities = useAppSelector(
    (state) => state.availability.availabilities
  );
  const loading = useAppSelector((state) => state.availability.loading);

  useEffect(() => {
    AvailabilityService.getAllAvailabilities();
  }, []);

  return (
    <>
      {/* <div className="pagination-list">
        <p> Availability</p>
      </div> */}

      <TableContainer>
        <Table
          aria-label="customized table"
          sx={{
            minWidth: "100%",
            borderSpacing: "0 10px",
            borderBottomWidth: "0px",
            borderCollapse: "separate",
          }}
        >
          <TableLoadingWrapper
            coloumns={6}
            loading={loading}
            length={7}
            message="There are nothing to show now"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>City</StyledTableCell>
                <StyledTableCell>Class</StyledTableCell>
                <StyledTableCell>Date & Time</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <BlockComponent permission={"availability:write"}>

                <StyledTableCell align="center">Action</StyledTableCell>
                </BlockComponent>
              </TableRow>
            </TableHead>
            <TableBody>
              {availabilities.map((availability) => (
                <StyledTableRow key={availability._id}>
                  {" "}
                  <StyledTableCell sx={{ textTransform: "capitalize" }}>
                    {availability.zone_id?.name||availability.city_id?.name}
                  </StyledTableCell>
                  <StyledTableCell>{availability.name}</StyledTableCell>
                  <StyledTableCell>
                    {DateService.getShortDateString(
                      availability.holiday_interval.start_time
                    )}
                    , &nbsp;
                    {DateService.getTimeString(
                      availability.holiday_interval.start_time
                    )}{" "}
                    -{" "}
                    {DateService.getShortDateString(
                      availability.holiday_interval.end_time
                    )}
                    , &nbsp;
                    {DateService.getTimeString(
                      availability.holiday_interval.end_time
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Chip status="Busy" />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <BlockComponent permission={"availability:write"}>

                    <Button
                      size="small"
                      variant="text"
                      onClick={() =>
                        dispatch(
                          availabilityActions.setAvailability(availability)
                        )
                      }
                    >
                      Edit
                    </Button>
                    <Button
                      size="small"
                      variant="text"
                      color="error"
                      onClick={() => {
                        SubscriptionService.subscribe(() => {
                          AvailabilityService.deleteAvailability(
                            availability?._id
                          );
                        });
                        dispatch(
                          modalActions.openModal({
                            width: "500px",
                            type: MODAL.CONFIRMATION_FORM,
                            data: {
                              heading: "Delete holiday",
                              message: "Do you really want to delete this?",
                            },
                          })
                        );
                      }}
                    >
                      Delete
                    </Button>
                    </BlockComponent>

                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TableLoadingWrapper>
        </Table>
      </TableContainer>
    </>
  );
}
