import http from "./http.service";
import Promisable from "./promisable.service";
import { availabilityActions } from "redux/slices/availability";
import { NavigateFunction } from "react-router-dom";
import { loaderActions } from "redux/slices/loader";
import { getAppDispatch } from "utils/dispatch.util";
import { formLoaderActions } from "redux/slices/formLoader";
import { modalActions } from "redux/slices/modal";

const url = "/class";

const AvailabilityService = {
  addAvailability: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/holiday-interval`, data)
    );

    if (success) {
      const availability = success.data.data.my_class;
      dispatch?.(availabilityActions.addAvailability(availability));
      navigate?.("/availability");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  updateAvailability: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/holiday-interval`, data)
    );

    if (success) {
      const availability = success.data.data.my_class;
      dispatch?.(
        availabilityActions.updateAvailability({
          id: availability._id,
          availability,
        })
      );
      navigate?.("/availability");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  getAvailability: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { availability } = success.data.data;
      dispatch?.(availabilityActions.setAvailability(availability));
    } else
      dispatch?.(availabilityActions.setAvailability({ data: "Not Found" }));

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },
  getAllAvailabilities: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(availabilityActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/get-holiday-interval`)
    );

    if (success) {
      const { classes } = success.data.data;
      dispatch?.(availabilityActions.setAvailabilities(classes));
    }

    dispatch?.(availabilityActions.setLoading(false));
    return [success, error];
  },
  deleteAvailability: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/delete-holiday-interval/${id}`)
    );

    if (success) {
      const classes = success.data.data.my_class;
      dispatch?.(modalActions.closeModal());
      dispatch?.(availabilityActions.setDeleteAvailabilities(classes));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default AvailabilityService;
