import { change } from "redux-form";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export default function UpdatePushNotificationForm({ id }: any) {
	const isSet = useRef(false);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const form = "BannerPicForm";
	const dynamicBanner = useAppSelector((state) => state.dynamics.dynamicBanner);

	useEffect(() => {
		if (isSet.current || !dynamicBanner || dynamicBanner?.data === "Not Found") return;

		const { media: image, title, description } = dynamicBanner;
		dispatch(change(form, "media", { blob: image }));
		dispatch(change(form, "title_en", title.en));
		dispatch(change(form, "title_ar", title.ar));
		dispatch(change(form, "description_en", description.en));
		dispatch(change(form, "description_ar", description.ar));

		isSet.current = true;
	}, [dynamicBanner, navigate, dispatch]);

	return null;
}
