import { useAppSelector } from "redux/hooks";
import GoBack from "components/atoms/GoBack";
import Container from "@mui/material/Container";
import Banner from "components/templates/Banner";
import { useNavigate, useParams } from "react-router-dom";
import CircleLoader from "components/atoms/CircleLoader";
import AddRoleForm from "./AddRoleForm";
import UpdateRoleForm from "./UpdateRoleForm";
import RoleService from "services/role.service";

export default function AddRole() {
  const { id } = useParams();
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.formLoader.loading);

	const handleSubmit = async (values: any) => {
    console.log("datadatadata",values);
    

		if(id) RoleService.updateRole(id,values,navigate)
		else 
		
			RoleService.addRole(values, navigate)
	  
  };

  return (
    <div>
      <Container>
        <GoBack path="/roles" title="Back to Roles" />
        <Banner heading={`${id ? "Update" : "Add"} Role`} />
        <div className="form">
          {loading && <CircleLoader />}

          <AddRoleForm onSubmit={handleSubmit} />

          {id && <UpdateRoleForm id={id} />}
        </div>
      </Container>
    </div>
  );
}
