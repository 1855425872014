import { email, password, required } from "utils/validate.util";
import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import SelectRedux from "components/molecules/SelectRedux";
import PhoneInputRedux from "components/molecules/PhoneInputRedux";
import SelectRole from "components/organisms/SelectRole";

export { default } from "./AddRoleForm";

export const fields: ReduxFormField[] = [
  {
    name: "first_name",
    label: "First Name",
    validate: [required],
    component: InputRedux,
    cellProps: { lg: 6 },
  },
  {
    name: "last_name",
    label: "Last Name",
    validate: [required],
    component: InputRedux,
    cellProps: { lg: 6 },
  },
  {
    name: "email",
    label: "Email",
    validate: [required, email],
    component: InputRedux,
    cellProps: { lg: 6 },
    InputProps: {
      type: "email",
    },
  },

  {
    name: "role_id",
    label: "Role",
    validate: [required],
    component: SelectRole,
    
    cellProps: { lg: 6 },
  },
];
