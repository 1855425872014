import http from "./http.service";
import { AppDispatch } from "redux/store";
import Promisable from "./promisable.service";
import { modalActions } from "redux/slices/modal";
import { payRollActions } from "redux/slices/payroll/payroll";
import DateService from "utils/date.util";

const PayRollService = {
  getAllEmployees: async (filters: any, dispatch?: AppDispatch) => {
    if (filters?.all) dispatch?.(modalActions.setLoading(true));
    else dispatch?.(payRollActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`/attendance`, filters)
    );

    if (success) {
      const { attendance, count } = success.data.data;

      if (filters?.all)
        dispatch?.(
          modalActions.updateData({
            table_data: attendance.map((user: any) => ({
              // ID: user.entry_id,
              ID: user.entry_id,
              "Employee Name": user.full_name,

              "CheckIn Time":
                DateService.getNumericDate(user.checkIn) +
                " " +
                DateService.getTimeString(user.checkIn),

              "CheckOut Time":
                DateService.getNumericDate(user?.checkOut) +
                " " +
                DateService.getTimeString(user?.checkOut),
              "Total Duration": user.duration,
            })),
          })
        );
      else dispatch?.(payRollActions.setEmployees({ attendance, count }));
    }
    if (filters?.all) dispatch?.(modalActions.setLoading(false));
    else dispatch?.(payRollActions.setLoading(false));

    return [success, error];
  },
  updatePayRoll: async (filters: any, dispatch?: AppDispatch) => {
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`/attendance`, filters)
    );

    if (success) {
      dispatch?.(modalActions.setLoading(false));

      dispatch?.(modalActions.closeModal());
      dispatch?.(payRollActions.setLoading(true));
      dispatch?.(payRollActions.refresh());
    }

    dispatch?.(modalActions.setLoading(false));
    dispatch?.(payRollActions.setLoading(true));
    dispatch?.(payRollActions.refresh());

    return [success, error];
  },
};

export default PayRollService;
