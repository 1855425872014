import http from "./http.service";
import Promisable from "./promisable.service";
import { NavigateFunction } from "react-router-dom";
import { loaderActions } from "redux/slices/loader";
import { getAppDispatch } from "utils/dispatch.util";
import { formLoaderActions } from "redux/slices/formLoader";
import { roleActions } from "redux/slices/role/roleSlice";

const url = "/roles";

const RoleService = {
  addRole: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`signup`, data)
    );

    if (success) {
      //   const { city } = success.data.data;
      //   dispatch?.(cityActions.addCity(city));
      navigate?.("/roles");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
updateRole: async (id: string, data: any, navigate?: NavigateFunction) => {
    
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`update_admin/${id}`, data)
    );

    if (success) {
      navigate?.("/roles");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  getAdminRoles: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(roleActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`admins`)
    );

    if (success) {
      const { admin ,role} = success.data.data;
      dispatch?.(roleActions.setRoles(admin));
      dispatch?.(roleActions.setPermissions(role));
    }

    dispatch?.(roleActions.setLoading(false));
    return [success, error];
  },
  getSelectedRoles: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(roleActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}`)
    );

    if (success) {
      const { roles } = success.data.data;
      dispatch?.(roleActions.setRoleOptions(roles));
    }

    dispatch?.(roleActions.setLoading(false));
    return [success, error];
  },
  addNewRole: async (data:any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(roleActions.setLoading(true));
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`,data)
    );

    if (success) {
      navigate?.("/roles");
    }

    dispatch?.(roleActions.setLoading(false));
    return [success, error];
  },

  getNewRole: async (data:any) => {
    const dispatch = getAppDispatch();
    dispatch?.(roleActions.setLoading(true));
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/privilege/${data}`)
    );

    if (success) {
      const { role } = success.data.data;
      dispatch?.(roleActions.setPrivilege(role))
      
    }

    dispatch?.(roleActions.setLoading(false));
    return [success, error];
  },
  updateNewRole: async (data:any,id:String,navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(roleActions.setLoading(true));
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/update/${id}`,data)
    );

    if (success) {
      navigate?.("/roles");

      
    }

    dispatch?.(roleActions.setLoading(false));
    return [success, error];
  },

  getRole: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`get-admin/${id}`)
    );

    if (success) {
      const { admin } = success.data.data;

      dispatch?.(roleActions.setRole(admin));
    } else dispatch?.(roleActions.setRole({ data: "Not Found" }));

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },
};

export default RoleService;
