import { useEffect } from "react";
import { change } from "redux-form";
import { classActions } from "redux/slices/class";
import ClassService from "services/class.service";
import SelectRedux from "components/molecules/SelectRedux";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import PrivilegeService from "utils/privilege.util";

export default function SelectZoneClass(props: any) {
  const { form } = props.meta;
  const dispatch = useAppDispatch();
  const classesOptions = useAppSelector((state) => state.class.classesOptions);
  const classesByZone = useAppSelector((state) => state.class.classesByZone);
  const zone_id = useAppSelector((state) => state.form?.[form]?.values?.zone_id);
  const permissions = useAppSelector((state) => state.auth?.user?.role_id?.permissions);


  useEffect(() => {
    if (PrivilegeService.checkPrivilege("class:read",permissions)) {
      ClassService.getAllClasses();

      return () => {
        dispatch(classActions.clear());
      };
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(change(form, "class_id", ""));
    console.log('zone_id', zone_id);

    dispatch(classActions.setClassesOptionsByZoneId(zone_id));
  }, [form, zone_id, classesByZone, dispatch]);

  return <SelectRedux {...props} options={classesOptions} />;
}
