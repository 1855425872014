import http from "./http.service";
import Promisable from "./promisable.service";
import { classActions } from "redux/slices/class";
import { NavigateFunction } from "react-router-dom";
import { loaderActions } from "redux/slices/loader";
import { getAppDispatch } from "utils/dispatch.util";

const url = "/class";

const ClassService = {
	addClass: async (data: any, navigate?: NavigateFunction) => {
		const dispatch = getAppDispatch();
		dispatch?.(loaderActions.setLoading(true));

		http.setJWT();
		http.setMultiPart();

		const [success, error]: any = await Promisable.asPromise(
			http.post(`${url}`, data)
		);

		if (success) {
			const { classs } = success.data.data;
			dispatch?.(classActions.addClass(classs));
			navigate?.("/class");
		}

		dispatch?.(loaderActions.setLoading(false));
		return [success, error];
	},
	updateClass: async (
		id: string,
		data: any,
		navigate?: NavigateFunction
	) => {
		const dispatch = getAppDispatch();
		dispatch?.(loaderActions.setLoading(true));

		http.setJWT();
		http.setMultiPart();

		const [success, error]: any = await Promisable.asPromise(
			http.patch(`${url}/${id}`, data)
		);

		if (success) {
			const { classs } = success.data.data;
			dispatch?.(classActions.updateClass({ id, classs }));
			navigate?.("/class");
		}

		dispatch?.(loaderActions.setLoading(false));
		return [success, error];
	},
	getClass: async (id: string) => {
		const dispatch = getAppDispatch();
		dispatch?.(loaderActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.get(`${url}/${id}`)
		);

		if (success) {
			const { classs } = success.data.data;
			dispatch?.(classActions.setClass(classs));
		} else dispatch?.(classActions.setClass({ data: "Not Found" }));

		dispatch?.(loaderActions.setLoading(false));
		return [success, error];
	},
	getAllClasses: async (params?: String) => {
		const dispatch = getAppDispatch();
		dispatch?.(classActions.setLoading(true));
		const query = params ? params : ""

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.get(`${url}${query}`)
		);

		if (success) {
			const { classes } = success.data.data;
			dispatch?.(classActions.setClasses(classes));
		}

		dispatch?.(classActions.setLoading(false));
		return [success, error];
	},

	getAllClassesByCity: async (data: any) => {
		const dispatch = getAppDispatch();
		dispatch?.(classActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.post(`${url}/city`, data)
		);

		if (success) {
			const { classes } = success.data.data;
			dispatch?.(classActions.setClassesOptions(classes));
		}

		dispatch?.(classActions.setLoading(false));
		return [success, error];
	},
	getAllTemplateClasses: async () => {
		const dispatch = getAppDispatch();
		dispatch?.(classActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.get(`${url}/classes-template`)
		);

		if (success) {
			const { classes } = success.data.data;
			dispatch?.(classActions.setClassesTemplate(classes));
		}

		dispatch?.(classActions.setLoading(false));
		return [success, error];
	},
};
export default ClassService;
