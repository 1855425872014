import CircleLoader from "components/atoms/CircleLoader";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import GoBack from "components/atoms/GoBack";
import Banner from "components/templates/Banner";
import AddInvoiceForm from "./AddInvoiceForm";
import InvoiceService from "services/invoice.service";
import ToasterService from "utils/toaster.util";
import { change } from "redux-form";

export default function AddInvoice() {
  const form = "AddInvoiceForm";
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.formLoader.loading);
  const dispatch = useAppDispatch();

  const totalPrice = useAppSelector(
    (state) => state.form?.[form]?.values?.total
  );
  const discount = useAppSelector(
    (state) => state.form?.[form]?.values?.total_discount
  );
  let totalDiscount = discount ? discount : 0;

  const vat = useAppSelector((state) => state.form?.[form]?.values?.vat);

  let totalee = totalPrice - totalDiscount;
  let taxable = totalee / 1.15;

  let newVat = totalee - taxable;

  useEffect(() => {
    dispatch(change(form, "is_payed", true));
  }, [dispatch]);

  useEffect(() => {
    if (!totalPrice) return;

    dispatch(change(form, "taxableAmount", taxable.toFixed(2)));
    dispatch(change(form, "vat", newVat.toFixed(2)));
    dispatch(change(form, "totalAmount", totalee.toFixed(2)));
  }, [dispatch, newVat, taxable, totalPrice, totalee, vat]);

  const handleSubmit = async (values: any) => {
    let data = { ...values };

    data.datee = new Date(data.date.date).toISOString();
    // data.invoice_date = new Date(data.invoice_date.date).toLocaleDateString();
    data.invoice_date = new Date(data.invoice_date.date).toISOString();

    data.time = new Date(data.time.date).toTimeString();
    const { pickup, destination } = data;

    const { vat, totalAmount, taxableAmount, total_discount, total } = data;

    data.pickup = pickup?.details?.formatted_address;
    data.destination = destination?.details?.formatted_address;

    data.vat = Number(vat);
    data.totalAmount = Number(totalAmount);
    data.taxableAmount = Number(taxableAmount);
    data.total_discount = Number(total_discount);
    data.total = Number(total);

    if (data.pickup === data.destination)
      return ToasterService.showError(
        "Pickup and Destination address must be different!"
      );
    delete data.date;

    InvoiceService.createInvoice(data, navigate);
  };

  return (
    <>
      <Container>
        <GoBack path="/customer-invoices" title="Back to Invoices" />
        <Banner heading={`Add Invoice`} />
        <div className="form" style={{ position: "relative" }}>
          {loading && <CircleLoader />}
          <AddInvoiceForm onSubmit={handleSubmit} />
        </div>
      </Container>
    </>
  );
}
