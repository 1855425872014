import { SelectOption } from "components/atoms/Select";
export { default } from "./Header";

export interface ILink {
	to: string;
	text: string;
	active: string;
	options?: SelectOption[];
}
export interface DropDown {
	to: string;
	text: string;
}

export const links: ILink[] = [
	{ to: "/bookings", text: "Bookings", active: "booking" },
	{ to: "/users", text: "Users", active: "user" },
	{ to: "/roles", text: "Roles", active: "role" },
	{ to: "/chauffeurs", text: "Chauffeurs", active: "chauffeur" },
	{ to: "/vehicles", text: "Vehicles", active: "vehicle" },
	{ to: "/models", text: "Models", active: "model" },
	{ to: "/partners", text: "Partners", active: "partner" },
	{ to: "/companies", text: "Companies", active: "compan" },
	{ to: "/class", text: "Class", active: "class" },
	{ to: "/city", text: "City", active: "city" },
	{ to: "/pricing", text: "Pricing", active: "pricing" },
	{ to: "/campaigns", text: "Campaigns", active: "campaign" },
	// { to: "/careers", text: "Careers", active: "careers" },
];

export const userDropDownList: DropDown[] = [
	{ text: "Dynamics", to: "/dynamics" },
	{ text: "Events", to: "/events" },
	{ text: "Fuels", to: "/fuels" },
	{ text: "Support", to: "/contacts" },
	{ text: "Commission", to: "/commission" },
	{ text: "Notifications", to: "/Notifications" },
	{ text: "Booking Ratings", to: "/booking-ratings" },
	{ text: "Partner Requests", to: "/partner-requests" },
	{ text: "Company Requests", to: "/company-requests" },
	{ text: "Reported Problems", to: "/reported-problems" },
	{ text: "Search History", to: "/search-history" },

]
export const invoiceList: DropDown[] = [
	{ text: "Customer Invoices", to: "/customer-invoices" },
	{ text: "Company Invoices", to: "/company-invoices" },
	{ text: "Partner Invoices", to: "/partner-invoices" },

]