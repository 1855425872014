import { date, required } from "utils/validate.util";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import SelectZoneClass from "components/organisms/SelectZoneClass";
import DateTimePickerRedux from "components/molecules/DateTimePickerRedux";
import SelectZone from "components/organisms/SelectZone/SelectZone";

export { default } from "./AddAvailabilityForm";

export const fields: ReduxFormField[] = [
  {
		name: "zone_id",
		label: "Zone",
		validate: [required],
		component: SelectZone,
		cellProps: { md: 4, lg: 4 },
		// SelectProps: { disabledOnUpdate: true },
	},
  {
    name: "class_id",
    label: "Class",
    validate: [required],
    component: SelectZoneClass,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "start_date",
    label: "Start Date",
    validate: [date],
    component: DateTimePickerRedux,
    cellProps: { md: 4, lg: 4 },
    DatePickerProps: {
      onChange: () => {},
      // minDate: new Date(),
      InputFieldProps: {},
      value: { date: "", error: false },
    },
  },
  {
    name: "end_date",
    label: "End Date",
    validate: [date],
    component: DateTimePickerRedux,
    cellProps: { md: 4, lg: 4 },
  },
];
