import { config } from "config";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ratingState, ResetFilters } from ".";

const default_page_size = config.PAGE_SIZE;

const initialState: ratingState = {
  loading: true,
  rating: [],
  refresh: 0,
  current_filters: {},
  refreshLoader: false,
  filters: { page: 1, page_size: default_page_size },
  count: 0,
};

export const ratingSlice = createSlice({
  name: "rating",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetFilters: (state) => {
			state.refresh += 1;
			state.refreshLoader = true;
			state.filters = initialState.filters;
			state.current_filters = initialState.current_filters;
		},
    setRating: (state, action) => {
      const { trips, count } = action.payload;
      state.rating = trips;
      state.count = count;
      state.refreshLoader = false;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
  },
});

const ratingReducer = ratingSlice.reducer;

export const ratingActions = ratingSlice.actions;
export default ratingReducer;
