import http from "./http.service";
import Promisable from "./promisable.service";
import { modalActions } from "redux/slices/modal";
import { getAppDispatch } from "utils/dispatch.util";
import { invoiceActions, InvoiceType } from "redux/slices/invoice";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import { loaderActions } from "redux/slices/loader";
import { customerInvoiceActions } from "redux/slices/customerInvoice/customerInvoiceSlice";

const url = "/invoices";

const InvoiceService = {
  getInvoices: async (type: InvoiceType, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(invoiceActions.setLoading({ type, loading: true }));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/${type}`, { ...data, isPartner: true })
    );

    if (success) {
      const { invoices } = success.data.data;
      dispatch?.(invoiceActions.setInvoices({ type, invoices }));
    }

    dispatch?.(invoiceActions.setLoading({ type, loading: false }));
    return [success, error];
  },

  generateInvoice: async (id: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/generateOne/${id}`, { isPartner: true })
    );

    if (success) {
      const { invoice } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(invoiceActions.updateInvoice({ type: "latest", invoice }));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  generateAllInvoices: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/generateAll`, { isPartner: true })
    );

    if (success) {
      const { invoices } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(invoiceActions.setInvoices({ type: "latest", invoices }));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  payInvoice: async (id: string, type: InvoiceType, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/payment/${id}`, { ...data, isPartner: true })
    );

    if (success) {
      const { invoice } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(invoiceActions.filterInvoices({ type, invoice }));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  verifyInvoice: async (id: string, type: InvoiceType) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/verify/${id}`)
    );

    if (success) {
      const { invoice } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(invoiceActions.filterInvoices({ type, invoice }));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  addInvoiceNotes: async (id: string, type: InvoiceType, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/notes/${id}`, { ...data, isPartner: true })
    );

    if (success) {
      const { invoice } = success.data.data;

      dispatch?.(modalActions.updateData({ admin_notes: invoice.admin_notes }));
      dispatch?.(invoiceActions.addInvoiceNotes({ type, invoice }));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  createInvoice: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/manual`, data)
    );

    if (success) {
      const data = success.data.data;
      navigate?.("/customer-invoices");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  getcustomerInvoices: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/customer-invoices`, { is_payed: data })
    );

    if (success) {
      const { invoices } = success.data.data;


      data === false
        ? dispatch?.(customerInvoiceActions.setCustomerOpenInvoice(invoices))
        : dispatch?.(customerInvoiceActions.setCustomerClosedInvoice(invoices));
    }

    dispatch?.(loaderActions.setLoading(false));

    return [success, error];
  },

  updateCustomerInvoiceStatus: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/updateCustomer-invoices/${id}`, { is_payed: !data })

    );

    if (success) {
      const status = success.data.data;
      dispatch?.(modalActions.closeModal());
      if (data === false) dispatch?.(customerInvoiceActions.filterOpenInvoice(id))
      if(data ===true) dispatch?.(customerInvoiceActions.filterCloseInvoice(id))

    }

    dispatch?.(modalActions.setLoading(false));


    return [success, error];
  },
};

export default InvoiceService;
