import AddAvailabilityForm from "./AddAvailabilityForm";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import UpdateAvailabilityForm from "./UpdateAvailabilityForm";
import { useNavigate } from "react-router-dom";
import CircleLoader from "components/atoms/CircleLoader";
import AvailabilityService from "services/availability.service";
import { format } from "date-fns";
import { change, reset } from "redux-form";

export default function AddAvailability() {
  const form = "AddAvailabilityForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const availability = useAppSelector(
    (state) => state.availability.availability
  );
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = async (values: any) => {
    let data = { ...values };
    data.holiday_interval = {
      start_time: format(
        new Date(new Date(data.start_date.date).toUTCString()),
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
      end_time: format(
        new Date(new Date(data.end_date.date).toUTCString()),
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
    };


    data.holiday_interval.start_time = `${data.holiday_interval.start_time}.000Z`;
    data.holiday_interval.end_time = `${data.holiday_interval.end_time}.000Z`;
    delete data.start_date;
    delete data.end_date;

    if (availability) await AvailabilityService.updateAvailability(data, navigate);
    else await AvailabilityService.addAvailability(data, navigate);
    dispatch(change(form, "start_date", { date: null, error: false }));
    dispatch(change(form, "end_date", { date: null, error: false }));
    dispatch(reset(form));

  };

  return (
    <div>
      <div className="form">
        {loading && <CircleLoader />}

        {availability && <UpdateAvailabilityForm />}
        <AddAvailabilityForm onSubmit={handleSubmit} />
      </div>
    </div>
  );
}
