import "./Banner.css";
import { BannerProps } from ".";

export default function Banner({ heading, children }: BannerProps) {
	return (
		<div className="banner-heading">
			{heading && <h2 className="heading-title">{heading}</h2>}
			<div className="banner-data">{children}</div>
		</div>
	);
}
