import { config } from "config";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ratingState, ResetFilters } from ".";

const default_page_size = config.PAGE_SIZE;

const initialState: ratingState = {
  loading: true,
  carMovements: [],
  carMovement: null,
  refresh: 0,
  current_filters: {},
  refreshLoader: false,
  filters: { page: 1, page_size: default_page_size },
  count: 0,
};

export const carMovementSlice = createSlice({
  name: "carMovement",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
    setCarMovements: (state, action) => {
      const { carMovements, count } = action.payload;
      state.carMovements = carMovements;
      state.count = count;
      state.refreshLoader = false;
    },
    setCarMovement: (state, action) => {
      state.carMovement = action.payload;
    },
    setFinishCarMovement: (state, action) => {
      const carMovement = action.payload;
      state.carMovements = state.carMovements.map((item) => {
        if (item._id === carMovement._id) {
          return carMovement;
        }
        return item;
      });

    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
  },
});

const carMovementReducer = carMovementSlice.reducer;

export const carMovementActions = carMovementSlice.actions;
export default carMovementReducer;
