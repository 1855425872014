import { Container } from "@mui/material";
import Banner from "components/templates/Banner";
import DynamicsList from "./DynamicsList";
import BannerPic from "./BannerPic";
import CrossCountryPrice from "./CrossCountryPrice";
import MovementType from "./DynamicCarMovement";
import Tabs from "components/templates/Tabs";
import { useState } from "react";
export default function City() {
	const [tab, setTab] = useState(0)
	return (
		<div>
			<Container maxWidth="lg">
				<Banner heading="Dynamics"></Banner>
				<Tabs
					value={tab}
					onChange={(tab) => setTab(tab)}
					tabs={[
						{ label: "Bookings Time", element: <DynamicsList /> },
						{ label: "App Banner Pic", element: <BannerPic /> },
						{ label: "Cross Country prices", element: <CrossCountryPrice /> },
						{ label: "Movement Type", element: <MovementType type="movementType" /> },
						{ label: "Platform Type", element: <MovementType type="platformType" /> },

					]}
				/>
			</Container>
		</div>
	);
}
