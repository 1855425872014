import { ClassState } from ".";
// import ToasterService from "utils/toaster.util";
import { SelectOption } from "components/atoms/Select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "config";


const default_page_size = config.PAGE_SIZE;

const initialState: ClassState = {
	classes: [],
	classs: null,
	loading: true,
	modelOptions: [],
	modelsDetails: {},
	classesByZone: {},
	classesOptions: [],
	allClassesOptions: [],
	refresh: 0,
	refreshLoader: false,
	current_filters: {},
	filters: { page: 1, page_size: default_page_size },
};

export const classSlice = createSlice({
	name: "class",
	initialState,
	reducers: {
		clear: (state) => {
			state.modelOptions = [];
			state.modelsDetails = {};
			state.classesOptions = [];
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		addClass: (state, action) => {
			let classesByZone: any = {};
			let classes = state.classes;
			classes.unshift(action.payload);
			classes.forEach((classs: any) => {
				const { city_id } = classs;
				let city_name = city_id?._id || "Undefined";
				if (classesByZone[city_name])
					classesByZone[city_name].classes.push(classs);
				else
					classesByZone[city_name] = {
						classes: [classs],
						city_name: city_id?.name,
					};
			});
			state.classes = classes;
			state.classesByZone = classesByZone;
		},
		updateClass: (state, action) => {
			const { id, classs } = action.payload;
			let classesByZone = state.classesByZone;
			state.classes.every(({ _id }, i) => {
				if (id === _id) {
					state.classes[i] = classs;
					return false;
				}
				return true;
			});
			for (const city in classesByZone) {
				if (Object.prototype.hasOwnProperty.call(classesByZone, city)) {
					let done = false;
					let element = classesByZone[city];
					element.classes.every(({ _id }: any, i: number) => {
						if (id === _id) {
							done = true;
							element.classes[i] = classs;
							return false;
						}
						return true;
					});
					if (done) break;
				}
			}
		},
		setClass: (state, action) => {
			state.classs = action.payload;
		},
		setClasses: (state, action) => {
			let details: any = {};
			let classesByZone: any = {};
			const classes = action.payload;
			let options: SelectOption[] = [];

			classes.forEach((classs: any) => {
				const { zone_id, city_id } = classs;
				let zone_name = zone_id?._id;
				if (zone_id) {
					if (classesByZone[zone_name])
						classesByZone[zone_name].classes.push(classs);
					else
						classesByZone[zone_name] = {
							models_length: 0,
							classes: [classs],
							zone_name: zone_id?.name,
						};
				}
			});

			Object.keys(classesByZone).forEach((zone_name) => {
				classesByZone[zone_name].classes.forEach((classs: any) => {
					classesByZone[zone_name].models_length += classs.models.length;
					classs.models.forEach((model: any) => {
						options.push({ label: model.name, value: model._id });
						details[model._id] = {
							...model,
							class_id: classs._id,
							class_name: classs.name,
						};
					});
				});
			});
			
			state.refreshLoader = false;
			state.classes = classes;
			state.modelOptions = options;
			state.modelsDetails = details;
			state.classesByZone = classesByZone;
		},
		setClassesOptions: (state, action) => {
			const classes = action.payload;
			let options: SelectOption[] = [];

			classes.forEach(({ _id, name }: any) => {
				options.push({ value: _id, label: name });
			});

			state.classesOptions = options;
		},
		setAllClassesOptions: (state, action) => {
			const classes = action.payload;
			let options: SelectOption[] = [];

			classes.forEach(({ _id, name }: any) => {

				options.push({ value: _id, label: name });
			});
			state.allClassesOptions = options;
		},
		setClassesOptionsByCity: (state, action) => {
			let found = false;
			const cities = action.payload;
			let options: SelectOption[] = [];
			const classesByZone = state.classesByZone;

			for (const city of cities) {
				for (const key in classesByZone) {
					if (Object.prototype.hasOwnProperty.call(classesByZone, key)) {
						const element = classesByZone[key];

						if (city === element.city_name) {
							found = true;
							options = element.classes.map(({ _id, name }: any) => ({
								value: _id,
								label: name,
							}));
							break;
						}
					}
				}
				if (found) break;
			}

			state.classesOptions = options;

			// if (options.length === 0)
			// 	ToasterService.showError(
			// 		"We are not Operational in selected Zone"
			// 	);
		},

		setClassesOptionsByCityId: (state, action) => {
			const city_id = action.payload;
			const classes = state.classesByZone?.[city_id]?.["classes"] || [];

			state.classesOptions = classes.map(({ _id, name }: any) => ({
				value: _id,
				label: name,
			}));
		},
		setClassesOptionsByZoneId: (state, action) => {
			const zone_id = action.payload;
			const classes = state.classesByZone?.[zone_id]?.["classes"] || [];
			state.classesOptions = classes.map(({ _id, name }: any) => ({
				value: _id,
				label: name,
			}));
		},
		setClassesTemplate: (state, action) => {
			const classes = action.payload;
			state.classes = classes;
		  },
		  setFilters: (state, action) => {
			state.filters = action.payload;
			state.refreshLoader = true;
		},
		setCurrentFilters: (state, action) => {
			state.current_filters = action.payload;
		},
		resetFilters: (state) => {
			state.refresh += 1;
			state.refreshLoader = true;
			state.filters = initialState.filters;
			state.current_filters = initialState.current_filters;
		},
	},
});

const classReducer = classSlice.reducer;

export const classActions = classSlice.actions;
export default classReducer;
