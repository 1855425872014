import http from "./http.service";
import Promisable from "./promisable.service";
import { getAppDispatch } from "utils/dispatch.util";
import { dynamicActions } from "redux/slices/dynamics/dynamicSlice";
import { modalActions } from "redux/slices/modal";
import { loaderActions } from "redux/slices/loader";

const url = "/dynamics";

const DynamicService = {

  updateDynamic: async (id: any, data: any) => {

    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      dispatch?.(modalActions.setLoading(false));
      DynamicService.getDynamics()

    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  getDynamics: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(dynamicActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}`)
    );

    if (success) {
      const { dynamics } = success.data.data;

      dispatch?.(dynamicActions.setdynamics(dynamics))
    }

    dispatch?.(dynamicActions.setLoading(false));
    return [success, error];
  },
  addBannerPic: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();
    http.setMultiPart();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/banner-pic`, data)
    );

    if (success) {
      const { dynamicBanner } = success.data.data;
      dispatch?.(dynamicActions.setdynamicBanner(dynamicBanner));

    }

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },
  getBannerPic: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();
    http.setMultiPart();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/banner-pic`)
    );

    if (success) {
      const { dynamicBanner } = success.data.data;
      dispatch?.(dynamicActions.setdynamicBanner(dynamicBanner));

    }

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },
  getCrossCountryPrices: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();
    http.setMultiPart();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/country-prices`)
    );

    if (success) {
      const { countryPrices } = success.data.data;
      dispatch?.(dynamicActions.setCountryPrices(countryPrices));

    }

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },
  updateCrossCountryPrice: async (id: any, data: any) => {

    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/country-price/${id}`, data)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      dispatch?.(modalActions.setLoading(false));
      DynamicService.getCrossCountryPrices();

    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  getDynamicCarMovement: async (type: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(dynamicActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/car-movement?type=${type}`)
    );

    if (success) {
      const { dynamicsMovement } = success.data.data;
      dispatch?.(dynamicActions.setDynamicCarMovement({ data: dynamicsMovement, type }));

    }
    dispatch?.(dynamicActions.setLoading(false));
    return [success, error];
  },
  addDynamicCarMovement: async (data: any) => {
    const dispatch = getAppDispatch();
    const { type } = data;
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/car-movement`, data)
    );

    if (success) {
      DynamicService.getDynamicCarMovement(type)
      dispatch?.(modalActions.closeModal());

    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  updateDynamicCarMovement: async (id: any, data: any) => {
    const dispatch = getAppDispatch();
    const { type } = data;
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/car-movement/${id}`, data)
    );

    if (success) {
      DynamicService.getDynamicCarMovement(type)
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default DynamicService;
