import { config } from "config";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fuelState, ResetFilters } from ".";

const default_page_size = config.PAGE_SIZE;

const initialState: fuelState = {
  loading: true,
  fuels: [],
  fuel: null,
  refresh: 0,
  current_filters: {},
  refreshLoader: false,
  filters: { page: 1, page_size: default_page_size },
  count: 0,
};

export const fuelSlice = createSlice({
  name: "fuel",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
    setFuels: (state, action) => {
      const { petros, count } = action.payload;
      state.fuels = petros;
      state.count = count;
      state.refreshLoader = false;
    },
    setFuel: (state, action) => {
      state.fuel = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
  },
});

const fuelReducer = fuelSlice.reducer;

export const fuelActions = fuelSlice.actions;
export default fuelReducer;
