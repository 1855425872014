import { fields } from ".";
import { Grid, Stack } from "@mui/material";
import { Field, reduxForm } from "redux-form";
import Button from "components/atoms/Button";
import { modalActions } from "redux/slices/modal";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import { requiredSelect } from "utils/validate.util";
import SelectRedux from "components/molecules/SelectRedux";
import { useEffect } from "react";
import CompanyService from "services/company.service";
import SelectPaymentOption from "components/organisms/SelectPaymentOption";

function UpdatePaymentStatusForm({ handleSubmit }: any) {
  const form ="UpdatePaymentStatusForm"
  const status = useAppSelector(
    (state) => state.form?.[form]?.values?.payment_status
  );


  const dispatch = useAppDispatch();

  useEffect(()=>{
    CompanyService.getPaymentMethods()
  })

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ReduxFormFields fields={fields} />
        {status === "paid" && (
          <>
            <br />
            <Grid item xs={12}>
              <Field
                name={"payment_method"}
                label={"Payment Method"}
                validate={[requiredSelect]}
                component={SelectPaymentOption}
              
              />
            </Grid>
          </>
        )}

        <br />
        <Stack direction="row" spacing={2}>
          <Button
            // color="secondary"
            variant="outlined"
            onClick={() => dispatch(modalActions.closeModal())}>
            Cancel
          </Button>
          <Button type="submit" disableElevation variant="contained">
            Save
          </Button>
        </Stack>
      </form>
    </div>
  );
}

export default reduxForm({ form: "UpdatePaymentStatusForm" })(
  UpdatePaymentStatusForm
);
