import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import SmartService from "services/smart.service";
import DateService from "utils/date.util";
import ObjectService from "utils/object.util";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import ClassChip from "components/atoms/ClassChip";
import Chip from "components/atoms/Chip";
import { Link } from "react-router-dom";
import { modalActions } from "redux/slices/modal";
export default function UpdateBookingStatus() {
  const { _id, type, source, zone } = useAppSelector(
    (state) => state.modal.data
  );
  const loading = useAppSelector((state) => state.modal.loading);

  const booking = useAppSelector((state) => state.smart.booking);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (_id) {
      SmartService.getOne(_id, source);
    }
  }, [_id, source]);
  return (
    <div>
      <h3> Booking Details</h3>

      {booking && (
        <>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Booking Id:
                  </TableCell>
                  <TableCell>

                    {source == "BLACKLANE" && (
                      <a
                        style={{
                          textDecoration: "none",
                          color: "rgb(0 0 0 / 70%)",
                        }}
                        href={`https://partner.blacklane.com/planned-detail/?id=${booking.source_id}`}
                        target="_blank"
                      >
                        {booking.booking_number}
                      </a>
                    )}
                    {source == "IYELO" && (
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "rgb(0 0 0 / 70%)",
                        }}
                        to={`/booking-details/${booking._id}`}
                        onClick={() => {
                          dispatch(modalActions.closeModal());
                        }}
                      >
                        {booking.booking_number}
                      </Link>
                    )}


                    <small> ({booking.source || source})</small>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Date & Time:
                  </TableCell>
                  <TableCell>
                    {DateService.getSmartDateString(booking.date)}
                    <br />
                    {DateService.getSmartTimeString(booking.date)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Customer:
                  </TableCell>
                  <TableCell>
                    {ObjectService.getFullName(booking.user_details)} <br />
                    {booking?.user_details.phone || booking?.user_details.email}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Route:
                  </TableCell>
                  <TableCell>
                    <div>
                      <div style={{ marginBottom: "6px" }}>
                        {booking.pickup.address}
                      </div>
                      {booking?.booking_type === "hourly" || booking?.trip_type === "hourly" ? (
                        <div style={{ fontSize: "12px", color: "red" }}>
                          {(Number(booking.duration))} hours
                        </div>
                      ) : (
                        <>
                          <div style={{ marginBottom: "6px" }}>
                            {booking.destination.address}
                          </div>{" "}
                          <span style={{ fontSize: "12px", color: "red" }}>
                            {booking.distance && (
                              <span>{booking.distance} Km</span>
                            )}
                            <span>
                              {/* ( estimated time ~{Number(booking.duration)?.toFixed(0)} mins) */}
                              (estimated time ~{booking?.source === "BLACKLANE" ? Number(booking.duration)?.toFixed(0) : (Number(booking.duration) * 60)?.toFixed(2)} mins)
                            </span>
                          </span>
                        </>
                      )}
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Class:
                  </TableCell>
                  <TableCell>
                    <ClassChip label={booking.class_name} size="small" />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Price:
                  </TableCell>
                  <TableCell>{Number(booking.price)?.toFixed?.(2)}€</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Accepted by:
                  </TableCell>
                  <TableCell>
                    <div>
                      {booking.is_assigned ? (
                        <>
                          {booking.driver_details ? (
                            <>
                              {ObjectService.getFullName(
                                booking.driver_details
                              )}
                            </>
                          ) : (
                            <span style={{ color: "rgb(183, 33, 54)" }}>
                              No chauffeur details
                            </span>
                          )}
                        </>
                      ) : (
                        <span style={{ color: "rgb(183, 33, 54)" }}>
                          No chauffeur assigned
                        </span>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
                {source != 'PRIVATE' && <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Status:
                  </TableCell>
                  <TableCell>
                    <Chip status={booking?.current_status} />
                  </TableCell>
                </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
}
