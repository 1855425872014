import { PartnerState } from ".";
import { SelectOption } from "components/atoms/Select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "config";

const default_page_size = config.PAGE_SIZE;

const initialState: PartnerState = {
  partners: [],
  partner: null,
  loading: true,
  partnerOptions: [],
  partnerCityOptions: [],
  partner_requests: [],
  partnerCityName: "",
  refresh: 0,
  refreshLoader: false,
  current_filters: {},
  filters: { page: 1, page_size: default_page_size },
};

export const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    clear: (state) => {
      state.partnerOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
    setPartnerCityName: (state, action) => {
      state.partnerCityName = action.payload;
    },
    addPartner: (state, action) => {
      state.partners.unshift(action.payload);
    },
    updatePartner: (state, action) => {
      const { id, partner } = action.payload;
      state.partners.every(({ _id }, i) => {
        if (id === _id) {
          state.partners[i] = partner;
          return false;
        }
        return true;
      });
    },
    setPartner: (state, action) => {
      state.partner = action.payload;
      if (action.payload == null) return
    },
    setPartners: (state, action) => {
      const partners = action.payload;
      let options: SelectOption[] = [];
      partners.forEach(({ _id, company_name }: any) => {
        options.push({ value: _id, label: company_name });
      });
      state.partnerOptions = options;
			state.refreshLoader = false;
      state.partners = action.payload;
    },
  },
});

const partnerReducer = partnerSlice.reducer;

export const partnerActions = partnerSlice.actions;
export default partnerReducer;
