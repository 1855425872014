import { useEffect } from "react";
import { useAppSelector } from "redux/hooks";
import SelectRedux from "components/molecules/SelectRedux";
import CompanyService from "services/company.service";
import RoleService from "services/role.service";

export default function SelectRole(props: any) {
  const roleOptions = useAppSelector(
    (state) => state.role.roleOptions
  );

  useEffect(() => {
    RoleService.getSelectedRoles()
  }, []);

  return <SelectRedux {...props} options={roleOptions} />;
}
