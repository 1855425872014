import { CareerState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: CareerState = {
	careers: [],
	career: null,
	loading: true,
	applicants: [],
	applicantObj: {},
};

export const careerSlice = createSlice({
	name: "career",
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		addCareer: (state, action) => {
			state.careers.unshift(action.payload);
		},
		updateCareer: (state, action) => {
			const { id, career } = action.payload;
			state.careers.every(({ _id }, i) => {
				if (id === _id) {
					state.careers[i] = career;
					return false;
				}
				return true;
			});
		},
		setCareer: (state, action) => {
			state.career = action.payload;
		},
		setCareerApplicants: (state, action) => {
			state.applicants = action.payload;
		},
		setCareers: (state, action) => {
			let applicantObj: any = {};
			const { careers, applicants } = action.payload;

			for (const { _id, count } of applicants) {
				applicantObj[_id] = count;
			}

			state.careers = careers;
			state.applicantObj = applicantObj;
		},
	},
});

const careerReducer = careerSlice.reducer;

export const careerActions = careerSlice.actions;
export default careerReducer;
