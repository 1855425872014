import http from "./http.service";
import Promisable from "./promisable.service";
import { modalActions } from "redux/slices/modal";
import ToasterService from "../utils/toaster.util";
import { loaderActions } from "redux/slices/loader";
import { getAppDispatch } from "utils/dispatch.util";
import { paymentActions } from "redux/slices/payment";
import BookingService from "./booking.service";

const url = "/payments";

const PaymentService = {
  getClientSecret: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/setupCard`)
    );

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },

  addPayPal: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/p/add`, data)
    );

    if (success) {
      await PaymentService.getAllMethods();
      ToasterService.showSuccess("Paypal Added Successfully");
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },

  deletePayPal: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/p/delete/${id}`)
    );

    if (success) {
      await PaymentService.getAllMethods();
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  deleteCreditCard: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/s/deleteCreditCard/${id}`)
    );

    if (success) {
      await PaymentService.getAllMethods();
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  getAllMethods: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(paymentActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/getAllMethods`)
    );

    if (success) {
      const { credit_cards, payment_methods } = success.data.data;
      dispatch?.(paymentActions.setMethods({ credit_cards, payment_methods }));
    }

    dispatch?.(paymentActions.setLoading(false));
    return [success, error];
  },

  setDefaultMethod: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/setdefaultMethod`, data)
    );

    if (success) {
      const { credit_cards, payment_methods } = success.data.data;
      dispatch?.(paymentActions.setMethods({ credit_cards, payment_methods }));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  updatePaymentStatus: async (id: any, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`trip/update-paymentStatus/${id}`, data)
    );

    if (success) {
		const { payment_status } = success.data.data.trip;
		
		// window.location.reload();
		dispatch?.(modalActions.closeModal());
      dispatch?.(paymentActions.setPayment(payment_status));
    BookingService.getBooking(`${id}`);
      
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  addAttachment: async (id: any, data: any) => {
    const dispatch = getAppDispatch();
  dispatch?.(modalActions.setLoading(true));
  
 

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`trip/update/attachment/${id}`, data)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
    BookingService.getBooking(`${id}`);


    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default PaymentService;
