import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AvailabilityState } from ".";

const initialState: AvailabilityState = {
  count: 0,
  refresh: 0,
  availabilities: [],
  availability: null,
  loading: true,
};

export const availabilitySlice = createSlice({
  name: "availability",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addAvailability: (state, action) => {
      state.availabilities.unshift(action.payload);
    },
    updateAvailability: (state, action) => {
      const { id, availability } = action.payload;
      state.availabilities.every(({ _id }, i) => {
        if (id === _id) {
          state.availabilities[i] = availability;
          return false;
        }
        return true;
      });
    },
    setAvailability: (state, action) => {
      state.availability = action.payload;
    },
    setAvailabilities: (state, action) => {
      state.availabilities = action.payload;
    },

    setDeleteAvailabilities: (state, action) => {
      let myArray = state.availabilities.filter(
        (obj) => obj._id !== action.payload._id
      );

      state.availabilities = myArray;
    },
  },
});

const availabilityReducer = availabilitySlice.reducer;

export const availabilityActions = availabilitySlice.actions;
export default availabilityReducer;
