import { date, required } from "utils/validate.util";
import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import DatePickerRedux from "components/molecules/DatePickerRedux";
import SelectZoneClass from "components/organisms/SelectZoneClass";
import TimePickerRedux from "components/molecules/TimePickerRedux";
import { digits } from "utils/normalize.util";
import SelectZone from "components/organisms/SelectZone/SelectZone";

export { default } from "./AddInvoiceForm";

export const hourlyfield: ReduxFormField[] = [
  {
    name: "is_payed",
    label: "Paid",
    component: CheckboxRedux,
    cellProps: { md: 1.2 },
  },
  {
    name: "hourly",
    label: "Hourly",
    component: CheckboxRedux,
    cellProps: { md: 10 },
  },
];
export const customerInfoField: ReduxFormField[] = [
  {
    name: "company_name",
    label: "Company name",
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "customer_name",
    label: "Customer name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "customer_vat",
    label: "Customer VAT Number",
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "address",
    label: "Address",
    component: InputRedux,

    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "postal_code",
    label: "Postal Code",

    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "city",
    label: "City",

    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "passport",
    label: "Passport / ID Number",
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
];

export const InvoicefieldsAfter: ReduxFormField[] = [
  {
    name: "invoice_date",
    label: "Invoice Date",
    component: DatePickerRedux,
    cellProps: { md: 4, lg: 4 },
    DatePickerProps: {
      onChange: () => {},
      // minDate: new Date(),
      InputFieldProps: {},
      value: { date: "", error: false },
    },
  },
  {
    name: "date",
    label: "Booking Date",
    validate: [date],
    component: DatePickerRedux,
    cellProps: { md: 4, lg: 4 },
    DatePickerProps: {
      onChange: () => {},
      // minDate: new Date(),
      InputFieldProps: {},
      value: { date: "", error: false },
    },
  },
  {
    name: "time",
    label: "Time",
    validate: [date],
    component: TimePickerRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "zone_id",
    label: "Zone",
    validate: [required],
    component: SelectZone,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "class_name",
    label: "Class",
    validate: [required],
    component: SelectZoneClass,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "total",
    label: "Total Price",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
    InputProps: { InputProps: { endAdornment: "SAR" } },
  },
  {
    name: "total_discount",
    label: "Total Discount",
    component: InputRedux,
    normalize:digits,
    cellProps: { md: 4, lg: 4 },
    InputProps: { InputProps: { endAdornment: "SAR" } },
  },
  {
    name: "taxableAmount",
    label: "Total Taxable Amount",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
    InputProps: { disabled: true, InputProps: { endAdornment: "SAR" } },
  },
  {
    name: "vat",
    label: "Vat",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
    InputProps: { disabled: true, InputProps: { endAdornment: "SAR" } },
  },
  {
    name: "totalAmount",
    label: "Total Amount",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
    InputProps: { disabled: true, InputProps: { endAdornment: "SAR" } },
  },
  // {
  // 	name: "driver_price",
  // 	label: "Driver Price",
  // 	validate: [required],
  // 	component: InputRedux,
  // 	cellProps: { md: 3, lg: 3 },
  // 	InputProps: { InputProps: { endAdornment: "SAR" } },
  // },
];
