import {  customerInfoField, hourlyfield, InvoicefieldsAfter } from ".";
import { reduxForm } from "redux-form";
import Button from "components/atoms/Button";
import ReduxFormFields from "components/molecules/ReduxFormFields";


import { Grid } from "@mui/material";
import { Field } from "redux-form";
import { required, requiredAutoComplete } from "utils/validate.util";
import AutoCompleteRedux from "components/molecules/AutoCompleteRedux";
import SelectRedux from "components/molecules/SelectRedux";
import { useAppSelector } from "redux/hooks";
import OptionService from "utils/option.util";


function AddInvoiceForm({ handleSubmit }: any) {
  const form = "AddInvoiceForm";

  	const hourly = useAppSelector(
      (state) => state.form?.[form]?.values?.hourly
    );
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ReduxFormFields fields={hourlyfield} />
        <ReduxFormFields fields={customerInfoField} />
        <Grid style={{padding:"16px 0"}} container spacing={2}>
          <Grid item md={6} lg={6}>
            <Field
              name={"pickup"}
              label={"Pickup"}
              validate={[requiredAutoComplete]}
              component={AutoCompleteRedux}
            />
          </Grid>

          {!hourly ? (
            <>
              <Grid item md={6} lg={6}>
                <Field
                  name={"destination"}
                  label={"Destination"}
                  validate={[requiredAutoComplete]}
                  component={AutoCompleteRedux}
                />
              </Grid>
              {/* <Grid item md={2} lg={2}>
                <Field
                  disabled={true}
                  name={"distance"}
                  label={"Distance"}
                  validate={[required]}
                  component={InputRedux}
                />
              </Grid> */}
            </>
          ) : (
            <>
              <Grid item md={6} lg={6}>
                <Field
                  name={"duration"}
                  label={"Duration"}
                  validate={[required]}
                  component={SelectRedux}
                  options={OptionService.getOptions(23, 2)}
                />
              </Grid>
              {/* <Grid item md={2} lg={2}>
                <Field
                  disabled={true}
                  name={"max_km"}
                  label={"Max KM"}
                  component={InputRedux}
                />
              </Grid> */}
            </>
          )}
        </Grid>
        <ReduxFormFields fields={InvoicefieldsAfter} />

        <br />
        <Button variant="contained" type="submit">
          Generate
        </Button>
      </form>
    </div>
  );
}
export default reduxForm({ form: "AddInvoiceForm" })(AddInvoiceForm);
