import { useEffect } from "react";
import Button from "components/atoms/Button";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Tooltip,
} from "@mui/material";
import { MODAL, modalActions } from "redux/slices/modal";
import DynamicService from "services/dynamics.service";
import InfoIcon from "@mui/icons-material/Info";
import BlockComponent from "components/organisms/BlockComponent/BlockComponent";
// import Tooltip from "components/atoms/Tooltip";

export default function CityList() {
  const dispatch = useAppDispatch();
  const dynamics = useAppSelector((state) => state.dynamics.dynamics);
  const loading = useAppSelector((state) => state.dynamics.loading);

  function capitalizeFirstLetter(str: any) {
    return str[0].toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    DynamicService.getDynamics();
  }, []);

  return (
    <TableContainer>
      <Table
        aria-label="customized table"
        sx={{
          minWidth: "100%",
          borderSpacing: "0 10px",
          borderBottomWidth: "0px",
          borderCollapse: "separate",
        }}>
        <TableLoadingWrapper
          coloumns={4}
          loading={loading}
          length={dynamics?.length}
          message="No Dynamics are available">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Customer Values</StyledTableCell>
              <StyledTableCell>Company Values</StyledTableCell>
              <BlockComponent permission={"dynamics:write"}>
                <StyledTableCell align="center">Action</StyledTableCell>
              </BlockComponent>
            </TableRow>
          </TableHead>
          <TableBody>
            {dynamics.map((dynamic: any, i: number) => (
              <StyledTableRow key={i + 1}>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <div style={{ width: "150px" }}>

                      {capitalizeFirstLetter(dynamic?.key)}
                    </div>
                    <Tooltip arrow title={dynamic.description}>
                      <InfoIcon style={{ color: "grey", fontSize: "20px", cursor: 'pointer' }} />
                    </Tooltip>
                  </div>
                </StyledTableCell>
                <StyledTableCell>{dynamic?.value?.user} min</StyledTableCell>
                <StyledTableCell>{dynamic?.value?.company} min</StyledTableCell>
                <StyledTableCell align="center">
                  <BlockComponent permission={"dynamics:write"}>

                    <Button
                      size="small"
                      variant="text"
                      onClick={() =>
                        dispatch(
                          modalActions.openModal({
                            type: MODAL.UPDATE_DYNAMICS,
                            data: {
                              id: dynamic?._id,
                              info: dynamic
                            },
                            width: "500px",
                          })
                        )
                      }>
                      Edit
                    </Button>
                  </BlockComponent>

                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </TableLoadingWrapper>
      </Table>
    </TableContainer>
  );
}
