import { useEffect } from "react";
import ZoneService from "services/zone.service";
import { zoneActions } from "redux/slices/zone";
import SelectRedux from "components/molecules/SelectRedux";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import PrivilegeService from "utils/privilege.util";

export default function SelectZone(props: any) {
	const dispatch = useAppDispatch();
	const zoneOptions = useAppSelector((state) => state.zone.zoneOptions);
	const permissions = useAppSelector((state) => state.auth?.user?.role_id?.permissions);

	useEffect(() => {
		if (PrivilegeService.checkPrivilege("zone:read",permissions)) {
			ZoneService.getAllZones();
			return () => {
				dispatch(zoneActions.clear());
			};
		}
	}, [dispatch]);

	return <SelectRedux {...props} options={zoneOptions} />;
}
