import { useAppDispatch, useAppSelector } from "redux/hooks";
import BookingService from "services/booking.service";
import RefundForm from "./RefundForm";
import { MODAL, modalActions } from "redux/slices/modal";
import SubscriptionService from "services/subscription.service";
import { useEffect } from "react";
import { change } from "redux-form";

export default function Refund() {
  let form = "RefundForm";
  const dispatch = useAppDispatch();
  // const _id = useAppSelector((state) => state.booking.booking?._id);
  const booking = useAppSelector((state) => state.booking.booking);
  const data = useAppSelector((state) => state.modal.data);
  const payment_method = useAppSelector((state) => state.form?.[form]?.values?.payment_method);
  const payment_reference = useAppSelector((state) => state.form?.[form]?.values?.payment_reference);

  useEffect(() => {
    if (payment_method === "Pay-fort" && booking?.added_by === "User")
      dispatch(change(form, "payment_reference", `Pay-fort# ${data}`));
    else if (payment_reference && payment_reference.includes("Pay-fort")) {
      dispatch(change(form, "payment_reference", ``));
    }

  }, [payment_method])

  const handleSubmit = async (values: any) => {
    let newValues = { ...values };
    newValues.amount = Number(newValues.amount);

    let data = { ...newValues };
    dispatch(
      modalActions.openModal({
        width: "500px",
        type: MODAL.CONFIRMATION_FORM,
        data: {
          heading: "Refund Amount",
          message: `Do you really want to refund this amount?`,
        },
      })
    );

    SubscriptionService.subscribe(() => {
      BookingService.refundBooking(booking?._id, data);
    });
  };

  return (
    <div>
      <h3>Refund Booking Payment</h3>
      <RefundForm onSubmit={handleSubmit} />
    </div>
  );
}
