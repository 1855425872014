import { ReduxFormField } from "components/molecules/ReduxFormFields";
import InputRedux from "components/molecules/InputRedux";

export { default } from "./UpdateDynamicsForm";


export const fields: ReduxFormField[] = [
  {
    name: "company",
    label: "Company Values",
    component: InputRedux,
    cellProps: { md: 12 },
  },
  {
    name: "user",
    label: "Customer Values",
    component: InputRedux,
    cellProps: { md: 12 },
  },
  {
    name: "description",
    label: "Description",
    component: InputRedux,
    cellProps: { md: 12 },
    InputProps: { multiline: true, rows: 3 },
  },
];
