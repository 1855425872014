import { useAppSelector } from "redux/hooks";
import Container from "@mui/material/Container";
import Banner from "components/templates/Banner";
import ImageService from "services/image.service";
import CircleLoader from "components/atoms/CircleLoader";
import DisplayImage from "components/atoms/DisplayImage";
import { useParams } from "react-router-dom";
import BannerPicForm from "./BannerPicForm";
import UpdateBannerPicForm from "./UpdateBannerPicForm";
import DynamicService from "services/dynamics.service";
import { useEffect } from "react";

export default function SendPushNotification() {
	const { id } = useParams();
	const form = "BannerPicForm";
	const loading = useAppSelector((state) => state.formLoader.loading);
	const dynamicBanner = useAppSelector((state) => state.dynamics.dynamicBanner);
	const image = useAppSelector((state) => state.form?.[form]?.values?.media);


	useEffect(() => {
		DynamicService.getBannerPic();
	}, []);



	const handleSubmit = async (values: any) => {
		let formData = new FormData();
		const { media: image, title_en, title_ar, description_ar, description_en } = values;
		if (image && image.name) {
			let img = await ImageService.getImageFileFromBlob(image);
			formData.append("media", img);
		} else if (image?.blob) {
			formData.append("media", image.blob);
		}
		const title: { [key: string]: string }  = { en: title_en, ar: title_ar }
		const description: { [key: string]: string } = { en: description_en, ar: description_ar }
		for (const lang in title) {
			if (Object.prototype.hasOwnProperty.call(title, lang)) {
				formData.append(`title[${lang}]`, title[lang]);
			}
		}
		for (const lang in description) {
			if (Object.prototype.hasOwnProperty.call(description, lang)) {
				formData.append(`description[${lang}]`, description[lang]);
			}
		}


		DynamicService.addBannerPic(formData);

	};


	return (
		<div>
			<Container>

				<Banner heading={`App Banner Picture`} />
				<div className="form">
					{loading && <CircleLoader />}

					{(image?.blob || dynamicBanner?.image) && (
						<DisplayImage
							alt={dynamicBanner?.name}
							src={image?.blob || dynamicBanner.image}
						/>
					)}

					<br />

					<BannerPicForm onSubmit={handleSubmit} />

					{dynamicBanner && <UpdateBannerPicForm id={id} />}
				</div>
			</Container>
		</div>
	);
}
