import { SelectOption } from "components/atoms/Select";
import { dynamicState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: dynamicState = {
  dynamics: [],
  dynamicBanner: null,
  countryPrices: [],
  dynamicCarMovements: [],
  movementTypeOptions: [],
  platformTypeOptions: [],
  loading: true,
};

export const dynamicSlice = createSlice({
  name: "dynamic",
  initialState,
  reducers: {
    setdynamics: (state, action) => {
      state.dynamics = action.payload;
    },
    setdynamicBanner: (state, action) => {
      state.dynamicBanner = action.payload;
    },
    setCountryPrices: (state, action) => {
      state.countryPrices = action.payload;
    },
    setDynamicCarMovement: (state, action) => {
      const { data, type } = action.payload;
      state.dynamicCarMovements = data;
      if (type === "movementType") {
        let options: SelectOption[] = [];
        data.forEach(({ _id, name, is_active }: any) => {
          if (is_active) {
            const option = { value: _id, label: `${name}` };
            options.push(option);
          }
        });
      state.movementTypeOptions = options;
      }
      if (type === "platformType") {
        let options: SelectOption[] = [];
        data.forEach(({ _id, name, is_active }: any) => {
          if (is_active) {
            const option = { value: _id, label: `${name}` };
            options.push(option);
          }
        });
      state.platformTypeOptions = options;
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const dynamicReducer = dynamicSlice.reducer;

export const dynamicActions = dynamicSlice.actions;
