import { fields } from ".";
import { Field, reduxForm } from "redux-form";
import Button from "components/atoms/Button";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import InputRedux from "components/molecules/InputRedux";
import { password, required } from "utils/validate.util";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import BlockComponent from "components/organisms/BlockComponent/BlockComponent";
function AddRoleForm({ handleSubmit }: any) {
  const { id } = useParams();
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ReduxFormFields fields={fields} />
        {!id && (
          <>
            <br />
            <Grid item md={12} lg={12}>
              <Field
                name={"password"}
                label={"Password"}
                validate={[required, password]}
                component={InputRedux}
                InputProps={{ showIcon: true, type: "password" }}
              />
            </Grid>
          </>
        )}

        <br />
        <BlockComponent permission={"role:write"}>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </BlockComponent>
      </form>
    </div>
  );
}
export default reduxForm({ form: "AddRoleForm" })(AddRoleForm);
