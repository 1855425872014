import { fields } from ".";
import { Stack } from "@mui/material";
import { reduxForm } from "redux-form";
import Button from "components/atoms/Button";
import { modalActions } from "redux/slices/modal";
import { useAppDispatch } from "redux/hooks";
import ReduxFormFields from "components/molecules/ReduxFormFields";

function AddAttachmentForm({ handleSubmit }: any) {
  const dispatch = useAppDispatch();

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ReduxFormFields fields={fields} />
        <br />
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            onClick={() => dispatch(modalActions.closeModal())}>
            Cancel
          </Button>
          <Button type="submit" disableElevation variant="contained">
            Save
          </Button>
        </Stack>
      </form>
    </div>
  );
}

export default reduxForm({ form: "AddAttachmentForm" })(AddAttachmentForm);
